<template>
    <v-snackbar
        v-model="visible"
        :color="type"
        class="font-weight-bold"
        :timeout="timeout"
    >
        <div v-html="message" class="font-weight-bold"></div>
      <template v-slot:action="{ attrs }">
        <v-btn
            dark
            text
            small
            v-bind="attrs"
            @click="visible = false"
        >
          {{ $t('commons.actions.close') }}
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>

    import { mapState } from 'vuex'

    export default {
        name: "SnackBar",

        computed: {
            ...mapState({
                message: state => state.snackbar.message,
                type: state => state.snackbar.type,
                timeout: state => state.snackbar.timeout,
            }),

            visible: {
                get() {
                    return this.$store.state.snackbar.visible
                },
                set() {
                    this.$store.dispatch('snackbar/resetSnackBar')
                }
            }
        }
    }
</script>

<style scoped>

</style>
