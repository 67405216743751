import apiClient from '@/plugins/apiClient'

export default {
     namespaced: true,
     state: {
         newsList: {"it":[],"us":[],"uk":[]},
         isLoading: false,
     },
     getters: {
          getNewsList(state) {
              return state.newsList
          },
          loadingState(state) {
            return state.isLoading
          },
     },
     mutations: {
          setNews(state, resp) {
              state.newsList = resp.data
          },
          loading(state, payload) {
               state.isLoading = payload
          },
     },  
     actions: {
          fetchNews({commit}, payload) {

               console.log('GET NEWS BLOG | news payload ' , payload)
   
               return new Promise((resolve, reject) => {
                   commit('loading', true)
   
                   apiClient.get('news')
                       .then(resp => {
                           commit('setNews', resp);
                           console.log('GET NEWS BLOG | news' , resp)
                           resolve(resp);
                           commit('loading', false)
                       })
                       .catch(err => {
                           commit('loading', false, {root: false})
                           reject(err);
                       });
               });
           },
     }
}
 