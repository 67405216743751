<template>
  <div>
    <v-navigation-drawer
        width="250"
        clipped
        :mini-variant="mini"
        mini-variant-width="80"
        v-model="sidebar"
        app
        bottom
    >
      <v-list dense>

        <v-list-item link :to="{name: 'dashboard'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.dashboard') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('sidebar.dashboard') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="canManage || isAnalytics" link :to="{name: 'users'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.users') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('sidebar.users') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="canManage || isCc" link :to="{name: 'stores-list'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.stores') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('sidebar.stores') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="canManage" link :to="{name: 'avatars-list'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.avatars') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('sidebar.avatars') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="canManage" link :to="{name: 'pages-list'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.pages') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('sidebar.pages') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="canManage" link :to="{name: 'missions'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.missions') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('sidebar.missions') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="canManage" link :to="{name: 'coupons'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.coupons') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('sidebar.coupons') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="canManage" link :to="{name: 'campaign-codes'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.campaign-codes') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('sidebar.campaign-codes') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="canManage" link :to="{name: 'discount-codes'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.discount-codes') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('sidebar.discount-codes') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="canManage" link :to="{name: 'push-notifications'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.push_notification') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('sidebar.push-notifications') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="canManage || isAnalytics" link :to="{name: 'ecommerce-coupon-codes'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.ecommerce_codes') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('sidebar.coupons-ecommerce-codes') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="canManage" link :to="{name: 'products'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.products') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('sidebar.products') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
        <v-list-item v-if="isAdmin" link :to="{name: 'receipts-modifier'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.receipts-modifier') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('sidebar.receipts-modifier') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="isAdmin" link :to="{name: 'overlays'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.overlays') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('sidebar.overlays') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="isAdmin" link :to="{name: 'exports'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.exports') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('sidebar.exports') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item v-if="isAdmin" link :to="{name: 'api-fails'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.api-fails') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('sidebar.api-fails') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
        <v-list-item v-if="isAdmin" link :to="{name: 'news-list'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.news') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('sidebar.news') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>

      <v-divider></v-divider>
      <v-list dense v-if="isAdmin">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t('sidebar.qrcode') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link :to="{name: 'qrcodes-bill-create'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.qrcode') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('qrcode.title') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>

      <v-list dense v-if="isAdmin || isExternal">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t('sidebar.settings.title') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="isAdmin" link :to="{name: 'admin-options'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.settings') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('sidebar.settings.admin-options') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="isAdmin" link :to="{name: 'admin-users'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.settings') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('sidebar.settings.admin-users') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="isAdmin" link :to="{name: 'coupon-thresholds-list'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.settings') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('sidebar.settings.coupon-threshold') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{name: 'passport-client-credentials'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.settings') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('sidebar.settings.passport-client-credentials') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>

      <v-divider></v-divider>

      <v-list dense v-if="isAdmin || canManage || isAnalytics || isStoreUser || isCc">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t('sidebar.ecommerce') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{name: 'ecommerce-orders-listing'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.ecommerce') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('ecommerce.orders.title') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list dense v-if="isStoreUser">
      <v-list-item link :to="{name: 'my-store'}">
        <v-list-item-action>
          <v-icon>{{ $t('icons.stores') }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t('sidebar.my-store') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list dense v-if="isAdmin || canManage || isAnalytics">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t('sidebar.analytics.title') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{name: 'analytics-users'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.settings') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('analytics.users.title') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{name: 'analytics-coupons'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.settings') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('analytics.coupons.title') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{name: 'analytics-receipts'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.settings') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('analytics.receipts.title') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{name: 'analytics-missions'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.settings') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('analytics.missions.title') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{name: 'venchi-reports'}">
          <v-list-item-action>
            <v-icon>{{ $t('icons.settings') }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('reports.title') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>

      <v-divider></v-divider>

    </v-navigation-drawer>

    <v-app-bar
        app
        flat
        color="primary"
        dark
        clipped-left
    >
      <v-app-bar-nav-icon @click.stop="sidebar = !sidebar"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ $appName }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <search-user v-if="canManage || isAnalytics"></search-user>

      <v-spacer></v-spacer>

      <!--            <v-btn icon>-->
      <!--                <v-icon>mdi-magnify</v-icon>-->
      <!--            </v-btn>-->

      <v-menu
          transition="slide-y-transition"
          bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon
                 v-bind="attrs"
                 v-on="on"
          >
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list dense>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-account-circle</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ $store.state.auth.username }}</v-list-item-title>
                <v-list-item-subtitle></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list dense>
            <v-list-item link @click="changeLocale">
              <v-list-item-action>
                <v-icon small>mdi-comment-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t('commons.actions.change-locale') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link @click="logout">
              <v-list-item-action>
                <v-icon small>mdi-logout</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

        </v-card>

      </v-menu>

    </v-app-bar>


  </div>
</template>

<script>

import {mapGetters} from "vuex";
import SearchUser from "@/components/commons/SearchUser";

export default {
  name: "NavigationDrawer",

  data() {
    return {
      mini: false,
      sidebar: null
    }
  },

  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      canManage: 'auth/canManageAppData',
      isAnalytics: 'auth/isAnalytics',
      isStoreUser: 'auth/isStoreUser',
      isCc: 'auth/isCcUser',
      isExternal: 'auth/isExternal',
    })
  },

  components: {SearchUser},

  methods: {
    logout() {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push('/login')
      })
    },
    changeLocale() {
      this.$store.dispatch('auth/setLocale')
    }
  }
}
</script>

<style scoped>
.tile {
  margin: 5px;
  border-radius: 4px;
}

.tile:hover {
  background: green;
}

.tile:active {
  background: yellow;
}
</style>
