import apiClient from '../../plugins/apiClient'
import i18n from "@/plugins/i18n";

export default {
    namespaced: true,
    state: {
        status: '',
        scopes: parseJwt(localStorage.getItem('token')),
        sc: localStorage.getItem('sc'),
        authenticated: localStorage.getItem('authenticated'),
        token: localStorage.getItem('token'),
        username: localStorage.getItem('user'),
        locale: localStorage.getItem('locale'),
    },

    getters: {
        scopes: state => state.scopes,
        scope: state => state.scopes[0],
        isAuthenticated: state => state.authenticated,
        isAdmin: state => state.scopes.includes('admin'),
        canManageAppData: state => (state.scopes.includes('admin') || state.scopes.includes('operator')),
        isAnalytics: state => (state.scopes.includes('analyst')),
        isStoreUser: state => (state.scopes.includes('store')),
        isCcUser: state => (state.scopes.includes('cc')),
        isExternal: state => state.scopes.includes('external'),
        token: state => state.token,
        username: state => state.username,
        sc: state => atob(state.sc).replace(state.token + '.', ''),
        authStatus: state => state.status,
        locale: state => state.locale,
    },

    actions: {
        login({ commit }, user) {
            return new Promise((resolve, reject) => {
                apiClient.post("login", user)
                    .then(resp => {
                        commit('success', resp.data);
                        commit('setLocale', resp.data.locale);

                        let scopes = parseJwt(resp.data.token)
                        commit('setScopes', scopes)

                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('store auth actions AUTH_REQUEST error ', err)
                        commit('error', err);
                        localStorage.removeItem("user-token");
                        reject(err);
                    });
            })
        },

        logout({ commit }) {
            return new Promise((resolve, reject) => {
                console.log('STORE AUTH | ACTIONS logout')
                apiClient.get('logout')
                    .then(resp => {
                        console.log('resp ', resp)
                        commit('logout')
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('logout err ', err)
                        reject(err);
                    })
            })
        },

        forceLogout({commit}) {
            commit('logout')
        },

        setScopes({commit}) {
            let scopes = parseJwt(localStorage.getItem('token'))
            commit('setScopes', scopes)
        },

        setLocale({commit, state}) {
            commit('setLocale', (state.locale === 'it') ? 'en' : 'it')
        }
    },

    mutations: {
        requestAuth(state) {
            state.status = "loading";
        },

        success(state, payload) {
            console.log('payload: ' , payload)
            state.status = "success";
            localStorage.setItem('sc', payload.sc)
            localStorage.setItem('user', payload.user)
            localStorage.setItem('token', payload.token)
            localStorage.setItem('authenticated', true)
            state.authenticated = true;
        },

        setScopes(state, payload) {
            state.scopes = payload
        },

        setInfo(state, payload) {
            state.info = payload
        },

        setLocale(state, payload) {
            state.locale = payload
            localStorage.setItem('locale', payload)
            i18n.locale = payload
        },

        error(state) {
            state.status = "error";
            state.authenticated = false
        },

        logout(state) {
            console.log('store mutations AUTH_LOGOUT')
            localStorage.clear()
            state.status = ''
            state.scopes = []
            state.authenticated = false
            state.token = ''
            state.username = ''
            state.sc = ''
        },
    },
}

function parseJwt (token) {
    if (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        let parsed = JSON.parse(jsonPayload);
        return parsed.scopes
    } else {
        return []
    }
}