import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import i18n from "@/plugins/i18n";
import apiClient from "@/plugins/apiClient";
import Utils from "@/plugins/utils";
import FlagIcon from 'vue-flag-icon'

Vue.prototype.$apiClient = apiClient
Vue.config.productionTip = false
Vue.prototype.$appName = process.env.VUE_APP_TITLE

Vue.use(Utils)
Vue.use(FlagIcon)

import VueHtmlToPaper from "vue-html-to-paper";
const options = {
  name: "_blank",
  specs: ["fullscreen=no", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900",
    "https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css",
    "https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css",
  ]
};
Vue.use(VueHtmlToPaper, options);

Vue.component('input-field', () => import('@/components/form/InputField'))
Vue.component('divider', () => import('@/components/commons/Divider'))
Vue.component('json-debug', () => import('@/components/commons/ObjDebug'))
Vue.component('overlay-loader', () => import('@/components/commons/OverlayLoader'))

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
