import apiClient from '../../plugins/apiClient'
import i18n from "@/plugins/i18n";

export default {
    namespaced: true,
    state: {
        isLoading: false,
        isLoadingProducts: false,
        isPatchingProducts: false,
        isPatchingStore: false,
        stores: [],
        store: {},
        products: [],
        cities: [],
        pagination: {
            current: 1,
            total: 0,
            per_page: 15
        },
    },

    getters: {
        loadingState(state) {
            return state.isLoading
        },

        loadingProductsState(state) {
            return state.isLoadingProducts
        },

        isPatchingProducts(state) {
            return state.isPatchingProducts
        },

        isPatchingStore(state) {
            return state.isPatchingStore
        },

        getStores(state) {
            return state.stores
        },

        getCities(state) {
            return state.cities
        },

        getStore(state) {
            return state.store
        },

        getProducts(state) {
            return state.products
        },

        getStoreById: (state) => (id) => {
            console.log('STORE STORE | GETTERS | getStoreById ' , id)
            console.log('STORE STORE | GETTERS | stores ' , state.stores)
            return state.stores.find(store => store.id === id)
        },

        getPagination(state) {
            return state.pagination
        },
    },

    actions: {
        // get stores
        fetchStores({commit, state}, payload) {

            console.log('STORE STORES | ACTIONS | fetchStores payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('setLoading', true)

                apiClient.get('stores?page=' + state.pagination.current)
                    .then(resp => {
                        commit('setLoading')
                        commit('setStores', resp.data.data);
                        commit('mutatePagination', resp.data.meta)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('setLoading')
                        reject(err);
                    });
            });
        },

        fetchStore({commit}, store_id) {

            console.log('STORE STORES | ACTIONS | fetchStore store_id ' , store_id)

            return new Promise((resolve, reject) => {
                commit('setLoading', true)

                apiClient.get('stores/' + store_id)
                    .then(resp => {
                        commit('setLoading')
                        commit('setStore', resp.data.data)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('setLoading')
                        reject(err);
                    });
            });
        },

        fetchStoreByAuthUser({commit}) {

            console.log('STORE STORES BY AUTH USER | ACTIONS ')

            return new Promise((resolve, reject) => {
                commit('setLoading', true)

                apiClient.get('ecommerce/my-store')
                    .then(resp => {
                        commit('setLoading')
                        commit('setStore', resp.data.data)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('setLoading')
                        reject(err);
                    });
            });
        },

        updateStore({commit}, payload) {

            console.log('STORE STORES | ACTIONS | updateStore payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('setPatchingStore', true)

                apiClient.patch('stores/' + payload.id, {
                    click_and_collect: payload.click_and_collect,
                    cc_emails: payload.cc_emails,
                    valid_caps: payload.valid_caps
                })
                    .then(resp => {
                        commit('setPatchingStore')
                        commit('setStore', resp.data.data)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('setPatchingStore')
                        reject(err);
                    });
            });
        },

        fetchStoreCities({commit}) {
            console.log('STORE STORES | ACTIONS | fetchStoreCities')

            return new Promise((resolve, reject) => {
                commit('setLoading', true)

                apiClient.get('stores/cities')
                    .then(resp => {
                        commit('setLoading')
                        commit('setStoreCities', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('setLoading')
                        reject(err);
                    });
            });
        },

        fetchProducts({commit}, store_id) {
            console.log('STORE STORES | ACTIONS | fetchProducts')

            return new Promise((resolve, reject) => {
                commit('setLoadingProducts', true)

                apiClient.get('stores/' + store_id + '/products')
                    .then(resp => {
                        commit('setLoadingProducts')
                        commit('setStoreProducts', resp.data.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('setLoadingProducts')
                        reject(err);
                    });
            });
        },

        getProductBySku({state, commit}, sku) {
            let product = state.products.find(store => store.sku_code === sku)
            commit('product/product', product, {root:true})
        },

        storeProductInfo({commit, dispatch}, payload) {
            console.log('STORE STORE | ACTIONS | store productInfo ' , payload)

            return new Promise((resolve, reject) => {
                commit('setPatchingProducts', true)

                apiClient.put('stores/' + payload.store_id + '/products', {
                    productData: payload.productData
                })
                    .then(resp => {
                        commit('setPatchingProducts', false)
                        dispatch('snackbar/showSnackBar', {message: i18n.t('products.create.messages.success'), type: 'success'}, {root: true})
                        dispatch('fetchProducts', payload.store_id)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('setPatchingProducts', false)
                        dispatch('snackbar/showSnackBar', {message: i18n.t('products.create.messages.fail'), type: 'error', err: err.response}, {root: true})
                        reject(err);
                    });
            });
        },

        search({commit}, city) {

            console.log('STORE USERS | ACTIONS | search stores by city  ' , city)

            return new Promise((resolve, reject) => {
                commit('setLoading', true)

                apiClient.get('stores?city=' + city)
                    .then(resp => {
                        commit('setStores', resp.data.data)
                        commit('setLoading', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('setLoading')
                        reject(err);
                    });
            });
        },

        setLoadingStatus({commit}, payload) {
            commit('loading', payload)
        },

        resetStore({commit}) {
            commit('resetStore')
        },

        reset({commit}) {
            commit('reset')
        }

    },

    mutations: {
        setStores(state, payload) {
            state.stores = payload
        },

        setStore(state, payload) {
            state.store = payload
        },

        setStoreProducts(state, payload) {
            state.products = payload
        },

        setStoreCities(state, payload) {
            state.cities = payload
        },

        mutatePagination(state, payload) {
            state.pagination.current = payload.current_page
            state.pagination.total = payload.last_page
            state.pagination.per_page = payload.par_page
        },

        setLoading(state, payload) {
            state.isLoading = payload
        },

        setLoadingProducts(state, payload) {
            state.isLoadingProducts = payload
        },

        setPatchingProducts(state, payload) {
            state.isPatchingProducts = payload
        },

        setPatchingStore(state, payload) {
            state.isPatchingStore = payload
        },

        resetStore(state) {
            console.log('reset store')
            state.store= {}
            state.products= []
        },

        reset(state) {
            state.isLoading= false
            state.stores= []
            state.store= {}
            state.pagination= {
                current: 1,
                total: 0,
                per_page: 15
            }
        }
    },
}
