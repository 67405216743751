import apiClient from '../../plugins/apiClient'
import i18n from "@/plugins/i18n";

export default {
    namespaced: true,
    state: {
        isLoading: false,
        avatars: [],
        avatar: {},
        pagination: {
            current: 1,
            total: 0,
            per_page: 15
        },
    },

    getters: {
        loadingState(state) {
            return state.isLoading
        },

        getAvatars(state) {
            return state.avatars
        },

        getAvatar(state) {
            return state.avatar
        },

        getAvatarById: (state) => (id) => {
            console.log('STORE AVATAR | GETTERS | getAvatarById ' , id)
            console.log('STORE AVATAR | GETTERS | avatars ' , state.avatars)
            return state.avatars.find(avatar => avatar.id === id)
        },

        getPagination(state) {
            return state.pagination
        },
    },

    actions: {
        // get avatars
        setAvatars({commit, state}, payload) {

            console.log('STORE AVATARS | ACTIONS | avatars payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.get('avatars?page=' + state.pagination.current)
                    .then(resp => {
                        commit('loading', false, {root: false})
                        commit('avatars', resp.data.data);
                        commit('pagination', resp.data.meta)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false, {root: false})
                        reject(err);
                    });
            });
        },

        setAvatar({commit}, avatar_id) {

            console.log('STORE AVATARS | ACTIONS | avatar avatar_id ' , avatar_id)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.get('avatars/' + avatar_id)
                    .then(resp => {
                        commit('loading')
                        commit('avatar', resp.data.avatar)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading')
                        reject(err);
                    });
            });
        },

        store({commit, dispatch}, payload) {
            console.log('STORE AVATARS | ACTIONS | store payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.post('avatars', payload)
                    .then(resp => {
                        commit('loading', false, {root: false})
                        commit('avatar', resp.data.avatar)
                        dispatch('snackbar/showSnackBar', {message: i18n.t('avatars.create.messages.success'), type: 'success'}, {root: true})
                        dispatch('setAvatars')
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false, {root: false})
                        dispatch('snackbar/showSnackBar', {message: i18n.t('avatars.create.messages.fail'), type: 'error', err: err.response, timeout: 8000}, {root: true})
                        reject(err);
                    });
            });
        },

        update({commit, dispatch}, payload) {
            console.log('STORE AVATARS | ACTIONS | store payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.patch('avatars/' + payload.id, payload)
                    .then(resp => {
                        commit('loading', false, {root: false})
                        commit('avatar', resp.data.avatar)
                        dispatch('snackbar/showSnackBar', {message: i18n.t('avatars.update.messages.success'), type: 'success'}, {root: true})
                        dispatch('setAvatars')
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false, {root: false})
                        dispatch('snackbar/showSnackBar', {message: i18n.t('avatars.update.messages.fail'), type: 'error', err: err.response, timeout: 8000}, {root: true})
                        reject(err);
                    });
            });
        },

        setLoadingStatus({commit}, payload) {
            commit('loading', payload)
        },

        reset({commit}) {
            commit('reset')
        }

    },

    mutations: {
        avatars(state, payload) {
            state.avatars = payload
        },

        avatar(state, payload) {
            state.avatar = payload
        },

        pagination(state, payload) {
            state.pagination.current = payload.current_page
            state.pagination.total = payload.last_page
            state.pagination.per_page = payload.par_page
        },

        loading(state, payload) {
            state.isLoading = payload
        },

        reset(state) {
            state.isLoading= false
            state.avatars= []
            state.pagination= {
                current: 1,
                total: 0,
                per_page: 15
            }
        }
    },
}
