import apiClient from '@/plugins/apiClient'

export default {
     namespaced: true,
     state:{
          overlay: null,
          overlays: [],
          meta: {
               current_page: 1,
               total: 0,
               per_page: 12
          },
          errors: {},
          message: null,
          loading: null
     },
     getters:{
          overlay: state => state.overlay,
          overlays: state => state.overlays,
          meta: state => state.meta,
          errors: state => state.errors,
          message: state => state.message,
          loading: state => state.loading,
     },
     mutations:{
          setLoading(state, val){
               state.loading = val
          },
          setOverlay(state,resp){
               console.log('mutation setOverlay',resp);
               state.overlay = resp
          },
          setOverlays(state,resp){
               console.log('mutation setOverlays',resp.data);
               state.overlays = resp.data
               console.log('mutation setMeta',resp.meta);
               state.meta = resp.meta
          },
          error(state, resp) {
               console.log('mutation setErrors',resp);
               state.errors = resp.errors
               state.message = resp.message
          },
          success(state, resp) {
               console.log('mutation success',resp);
               state.errors = {}
          },
          addTo(state,resp){
               console.log('mutation addTo',resp);
               state.overlays.unshift(resp)
          },
     },
     actions:{
          list({commit,state}, params){
               commit('setLoading', true);
               return new Promise((resolve, reject) => {
                   console.log('GET Overlays on page: '+state.meta.current_page, params)

                   apiClient.get("overlays?page="+state.meta.current_page).then(resp => {
                         commit('setOverlays', resp.data);
                         commit('success', resp.data);
                         commit('setLoading', false);
                         resolve(resp);
                   })
                   .catch(err => {
                       console.log('GET pages ERROR', err)
                       commit('error', err.response);
                       commit('setLoading', false);
                       reject(err);
                   });
               })
          },
          create({commit},data){
               return new Promise((resolve, reject) => {
                    console.log('create single soverlay',data)
                    apiClient.post("overlays/create/", data).then(resp => {
                         commit('addTo', resp.data);
                         commit('success', resp.data);
                         resolve(resp);
                    })
                    .catch(err => {
                         console.log('create single soverlay ERROR', err)
                         commit('error', err.response.data);
                         reject(err);
                    });
               })
          },
          update({commit},data){
               return new Promise((resolve, reject) => {
                    console.log('create single soverlay',data)
                    apiClient.patch("overlays/update/"+data.id, data).then(resp => {
                         commit('success', resp.data);
                         resolve(resp);
                    })
                    .catch(err => {
                         console.log('create single overlay ERROR', err)
                         commit('error', err.response.data);
                         reject(err);
                    });
               })
          },
     }
}
