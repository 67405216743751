import apiClient from '../../plugins/apiClient'
import i18n from "@/plugins/i18n";

const querystring = require('querystring');
const baseUserData = () => {
    return {
        data: [],
        pagination: {
            current: 1,
            total: 0,
            per_page: 10
        }
    }
}

export default {
    namespaced: true,
    state: {
        isLoading: false,
        isLoadingMissions: false,
        isLoadindCoupons: false,
        isLoadindUsedCoupons: false,
        isLoadingInvited: false,
        isLoadingReceipts: false,
        isLoadingOrders: false,
        isLoadingCrmBackup: false,
        users: [],
        user: {},
        userCoupons: baseUserData(),
        userUsedCoupons: baseUserData(),
        userMissions: baseUserData(),
        userReceipts: baseUserData(),
        userOrders: baseUserData(),
        userCrmBackup: {},
        links: {},
        bo_users: [],
        bo_user: {},
        search: {
            loading: false,
            results: []
        },
        invited: {
            data: []
        },
        pagination: {
            current: 1,
            total: 0,
            per_page: 15
        },
    },

    getters: {
        loadingState(state) {
            return state.isLoading
        },

        loadingMissionsState(state) {
            return state.isLoadingMissions
        },

        loadingCouponsState(state) {
            return state.isLoadindCoupons
        },

        loadingUsedCouponsState(state) {
            return state.isLoadindUsedCoupons
        },

        loadingReceiptsState(state) {
            return state.isLoadingReceipts
        },

        loadingInvitedState(state) {
            return state.isLoadingInvited
        },

        loadingOrdersState(state) {
            return state.isLoadingOrders
        },

        loadingSearchState(state) {
            return state.search.loading
        },

        loadingCrmBackup(state){
            return state.isLoadingCrmBackup
        },

        getUsers(state) {
            return state.users
        },

        getBoUsers(state) {
            return state.bo_users
        },

        getUser(state) {
            return state.user
        },

        getUserProfile(state) {
            return state.user.profile
        },

        getInvited(state) {
            return state.invited.data
        },

        getUserMissions(state) {
            return state.userMissions.data
        },

        getUserReceipts(state) {
            return state.userReceipts.data
        },

        getUserOrders(state) {
            return state.userOrders.data
        },

        getUserCrmBackup(state){
            return state.userCrmBackup.data
        },

        getSearchResults(state) {
            return state.search.results
        },

        getUserMissionsPagination(state) {
            return state.userMissions.pagination
        },

        getUserReceiptsPagination(state) {
            return state.userReceipts.pagination
        },

        getUserOrdersPagination(state) {
            return state.userOrders.pagination
        },

        getUserCoupons(state) {
            return state.userCoupons.data
        },

        getUserUsedCoupons(state) {
            return state.userUsedCoupons.data
        },

        getUserCouponsPagination(state) {
            return state.userCoupons.pagination
        },

        getUserUsedCouponsPagination(state) {
            return state.userUsedCoupons.pagination
        },

        getUserById: (state) => (id) => {
            console.log('STORE USER | GETTERS | getUserById ', id)
            console.log('STORE USER | GETTERS | users ', state.users)
            return state.users.find(user => user.id === id)
        },

        getPagination(state) {
            return state.pagination
        },

        getLinks(state) {
            return state.links
        }
    },

    actions: {

        fetchBoUsers({commit, state}, payload) {

            console.log('STORE USERS | ACTIONS | fetchBoUsers payload ', payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.get('users/admins?&page=' + state.pagination.current)
                    .then(resp => {
                        commit('boUsers', resp.data.data);
                        commit('pagination', resp.data.meta)
                        commit('loading', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false, {root: false})
                        reject(err);
                    });
            });
        },

        storeBoUser({commit, dispatch}, payload) {
            console.log('STORE USERS | ACTIONS | storeBoUser payload ', payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.post('users/invite/create/' + payload.token, payload.user)
                    .then(resp => {
                        dispatch('snackbar/showSnackBar', {
                            message: i18n.t('users.create.messages.success'),
                            type: 'success'
                        }, {root: true})
                        commit('loading', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false, {root: false})
                        dispatch('snackbar/showSnackBar', {
                            message: i18n.t('users.create.messages.fail'),
                            type: 'error',
                            err: err.response,
                            timeout: 8000
                        }, {root: true})
                        reject(err);
                    });
            });
        },

        fetchUsers({commit}, payload) {

            console.log('STORE USERS | ACTIONS | fetchUsers payload ', payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                let url = (!payload.link) ? 'users?page=1' : payload.link

                apiClient.get(url + '&' + querystring.stringify(payload))
                    .then(resp => {
                        commit('users', resp.data.data);
                        commit('pagination', resp.data)
                        // commit('pagination', resp.data.meta)
                        // commit('links', resp.data.links)
                        commit('links', resp.data)
                        commit('loading', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false, {root: false})
                        reject(err);
                    });
            });
        },

        fetchUser({commit}, user_id) {

            console.log('STORE USERS | ACTIONS | fetchUser user_id ', user_id)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.get('users/' + user_id + '/view')
                    .then(resp => {
                        commit('user', resp.data.data)
                        commit('loading', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading')
                        reject(err);
                    });
            });
        },

        searchUser({commit}, email) {

            console.log('STORE USERS | ACTIONS | searchUser email ', email)

            return new Promise((resolve, reject) => {
                commit('searching', true)

                apiClient.get('users/find-by-email?email=' + email)
                    .then(resp => {
                        commit('searchResults', resp.data.data)
                        commit('searching', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('searching')
                        reject(err);
                    });
            });
        },

        patchUserIsTester({commit, dispatch}, payload) {
            console.log('STORE USER | ACTIONS | patchUserIsTester | payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.patch('users/is-tester/' + payload.user, payload)
                    .then(resp => {
                        dispatch('snackbar/showSnackBar', {message: i18n.t('users.manage_beta_tester.messages.success'), type: 'success'}, {root: true})
                        dispatch('user/fetchUser', payload.user, {root: true})
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false, {root: false})
                        dispatch('snackbar/showSnackBar', {message: i18n.t('users.manage_beta_tester.messages.fail'), type: 'error', err: err.response, timeout: 8000}, {root: true})
                        reject(err);
                    });
            });
        },

        fetchUserMissions({commit, state}, user_id) {

            console.log('STORE USERS | ACTIONS | fetchUserMissions for user ', user_id)

            return new Promise((resolve, reject) => {
                commit('loadingMissions', true)

                apiClient.get('users/' + user_id + '/missions?page=' + state.userMissions.pagination.current)
                    .then(resp => {
                        commit('missions', resp.data.data)
                        commit('missionsPagination', resp.data.meta)
                        commit('loadingMissions', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loadingMissions')
                        reject(err);
                    });
            });
        },

        fetchUserCoupons({commit, state}, user_id) {

            console.log('STORE USERS | ACTIONS | fetchUserCoupons for user ', user_id)

            return new Promise((resolve, reject) => {
                commit('loadingCoupons', true)

                apiClient.get('users/' + user_id + '/coupons?page=' + state.userCoupons.pagination.current)
                    .then(resp => {
                        commit('coupons', resp.data.data)
                        commit('couponsPagination', resp.data.meta)
                        commit('loadingCoupons', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loadingCoupons', false, {root: false})
                        reject(err);
                    });
            });
        },

        fetchUserUsedCoupons({commit, state}, user_id) {

            console.log('STORE USERS | ACTIONS | fetchUserUsedCoupons for user ', user_id)

            return new Promise((resolve, reject) => {
                commit('loadingUsedCoupons', true)

                apiClient.get('users/' + user_id + '/used_coupons?page=' + state.userUsedCoupons.pagination.current)
                    .then(resp => {
                        commit('usedCoupons', resp.data.data)
                        commit('usedCouponsPagination', resp.data.meta)
                        commit('loadingUsedCoupons', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loadingUsedCoupons', false, {root: false})
                        reject(err);
                    });
            });
        },

        fetchUserReceipts({commit, state}, user_id) {

            console.log('STORE USERS | ACTIONS | fetchUserReceipts for user ', user_id)

            return new Promise((resolve, reject) => {
                commit('loadingReceipts', true)

                apiClient.get('users/' + user_id + '/receipts?page=' + state.userReceipts.pagination.current)
                    .then(resp => {
                        commit('receipts', resp.data.data)
                        commit('receiptsPagination', resp.data.meta)
                        commit('loadingReceipts', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loadingReceipts', false, {root: false})
                        reject(err);
                    });
            });
        },

        fetchUserOrders({commit, state}, user_id) {

            console.log('STORE USERS | ACTIONS | fetchUserOrders for user ', user_id)

            return new Promise((resolve, reject) => {
                commit('loadingOrders', true)

                apiClient.get('ecommerce/orders?page=' + state.userOrders.pagination.current + '&user=' + user_id)
                    .then(resp => {
                        commit('orders', resp.data.data)
                        commit('ordersPagination', resp.data.meta)
                        commit('loadingOrders', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loadingOrders', false, {root: false})
                        reject(err);
                    });
            });
        },

        fetchInvited({commit}, payload) {

            console.log('STORE USERS | ACTIONS | fetchInvited for user code ', payload.code)

            return new Promise((resolve, reject) => {
                commit('loadingInvited', true)

                apiClient.get('users/' + payload.user_id + '/invited/' + payload.code)
                    .then(resp => {
                        commit('invited', resp.data.data)
                        commit('loadingInvited', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loadingInvited', false, {root: false})
                        reject(err);
                    });
            });
        },

        fetchCrmBackup({commit}, payload) {

            console.log('STORE USERS | ACTIONS | fetchCrmBackup for user ', payload.user_id)

            return new Promise((resolve, reject) => {
                commit('loadingCrmBackup', true)

                apiClient.get('users/' + payload.user_id + '/venchi-crm/view' )
                    .then(resp => {
                        commit('crmBackup', resp.data)
                        commit('loadingCrmBackup', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loadingCrmBackup', false, {root: false})
                        reject(err);
                    });
            });
        },

        runCrmBackup({commit}, payload){
            console.log('STORE USERS | ACTIONS | runCrmBackup for user ', payload.user_id)

            return new Promise((resolve, reject) => {
                commit('loadingCrmBackup', true)

                apiClient.post('users/' + payload.user_id + '/venchi-crm/manage' )
                    .then(resp => {
                        console.log('OK',resp)
                        commit('crmBackup', {})
                        commit('loadingCrmBackup', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loadingCrmBackup', false, {root: false})
                        reject(err);
                    });
            });
        },

        updateUserCoupon({commit}, payload){


            return new Promise((resolve, reject) => {
                commit('loadingCoupons', true)

                apiClient.patch('users/' + payload.user_id + '/coupons/'+ payload.coupon_id+'/update', payload.action )
                    .then(resp => {
                        console.log('OK',resp)
                        commit('loadingCoupons', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loadingCoupons', false)
                        reject(err);
                    });
            });


        },

        updateUserMission({commit}, payload){
            return new Promise((resolve, reject) => {
                commit('loadingMissions', true)
                apiClient.patch('users/' + payload.user_id + '/missions/'+ payload.mission_id+'/manage', payload.action )
                    .then(resp => {
                        console.log('OK',resp)
                        commit('loadingMissions', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loadingMissions', false)
                        reject(err);
                    });
            });
        },

        manageCountry({commit}, data){
            return new Promise((resolve, reject) => {
                commit('loading',true);
                apiClient.post('users/' + data.user_id + '/country/manage', data )
                    .then(resp => {
                        console.log('OK',resp)
                        commit('user', resp.data.data)
                        commit('loading', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        deleteUser({commit}, user_id){

            return new Promise((resolve, reject) => {
                commit('loading',true);
                apiClient.delete('users/' + user_id + '/delete' )
                    .then(resp => {
                        console.log('OK', resp)
                        commit('user', {})
                        commit('loading', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });

        },

        restoreUser({commit}, user_id){

            return new Promise((resolve, reject) => {
                commit('loading',true);
                apiClient.post('users/' + user_id + '/restore' )
                    .then(resp => {
                        console.log('OK', resp)
                        commit('user', resp.data.data)
                        commit('loading', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });

        },

        setLoadingStatus({commit}, payload) {
            commit('loading', payload)
        },

        reset({commit}) {
            commit('reset')
        },

        resetUserData({commit}) {
            commit('resetUserData')
        }
    },

    mutations: {
        users(state, payload) {
            state.users = payload
        },

        user(state, payload) {
            state.user = payload
        },

        invited(state, payload) {
            state.invited.data = payload
        },

        missions(state, payload) {
            state.userMissions.data = payload
        },

        coupons(state, payload) {
            state.userCoupons.data = payload
        },

        usedCoupons(state, payload) {
            state.userUsedCoupons.data = payload
        },

        receipts(state, payload) {
            state.userReceipts.data = payload
        },

        orders(state, payload) {
            state.userOrders.data = payload
        },

        crmBackup(state, payload) {
            console.log('set crmBackup',payload)
            state.userCrmBackup = payload
        },

        searchResults(state, payload) {
            state.search.results = payload
        },

        couponsPagination(state, payload) {
            state.userCoupons.pagination.current = payload.current_page
            state.userCoupons.pagination.total = payload.last_page
            state.userCoupons.pagination.per_page = payload.par_page
        },

        usedCouponsPagination(state, payload) {
            state.userUsedCoupons.pagination.current = payload.current_page
            state.userUsedCoupons.pagination.total = payload.last_page
            state.userUsedCoupons.pagination.per_page = payload.par_page
        },

        missionsPagination(state, payload) {
            state.userMissions.pagination.current = payload.current_page
            state.userMissions.pagination.total = payload.last_page
            state.userMissions.pagination.per_page = payload.par_page
        },

        receiptsPagination(state, payload) {
            state.userReceipts.pagination.current = payload.current_page
            state.userReceipts.pagination.total = payload.last_page
            state.userReceipts.pagination.per_page = payload.par_page
        },

        ordersPagination(state, payload) {
            state.userOrders.pagination.current = payload.current_page
            state.userOrders.pagination.total = payload.last_page
            state.userOrders.pagination.per_page = payload.par_page
        },

        boUsers(state, payload) {
            state.bo_users = payload
        },

        validInviteToken(state, payload) {
            state.validInviteToken = payload
        },

        pagination(state, payload) {
            state.pagination.current = payload.current_page
            state.pagination.total = payload.last_page
            state.pagination.per_page = payload.par_page
        },

        links(state, payload) {
            console.log('set links payload ', payload)
            // state.links = payload
            state.links = {
                "prev": payload.prev_page_url,
                "next": payload.next_page_url
            }
        },

        loading(state, payload) {
            state.isLoading = payload
        },

        searching(state, payload) {
            state.search.loading = payload
        },

        loadingMissions(state, payload) {
            state.isLoadingMissions = payload
        },

        loadingCoupons(state, payload) {
            state.isLoadindCoupons = payload
        },

        loadingUsedCoupons(state, payload) {
            state.isLoadindUsedCoupons = payload
        },

        loadingReceipts(state, payload) {
            state.isLoadingReceipts = payload
        },

        loadingInvited(state, payload) {
            state.isLoadingInvited = payload
        },

        loadingOrders(state, payload) {
            state.isLoadingOrders = payload
        },

        loadingCrmBackup(state, payload) {
            state.isLoadingCrmBackup = payload
        },

        reset(state) {
            state.isLoading = false
            state.users = []
            state.pagination = {
                current: 1,
                total: 0,
                per_page: 15
            }
        },

        resetUserData(state) {
            console.log('resetUserData')
            state.userCoupons = baseUserData()
            state.userMissions = baseUserData()
            state.userReceipts = baseUserData()
            state.userUsedCoupons = baseUserData()
        }
    },
}
