// index.js
import commons from './commons.json'
import dashboard from './dashboard.json'
import sidebar from './sidebar.json'
import icons from './../icons.json'
import users from './users.json'
import missions from './missions.json'
import coupons from './coupons.json'
import auth from './auth.json'
import stores from './stores.json'
import images from './images.json'
import avatars from './avatars.json'
import pages from './pages.json'
import products from './products.json'
import discountcode from './discountcode.json'
import options from './options.json'
import search from './search.json'
import analytics from './analytics.json'
import push_notitications from './push-notifications.json'
import ecommerce from './ecommerce.json'
import notes from './notes.json'
import account from './account.json'
import campaign_codes from './campign_codes.json'
import receipts from './receipts.json'
import oauth from "@/locales/it/oauth.json";
import reports from './reports.json'
import news from './news.json'

const en = {
    commons: commons,
    auth: auth,
    icons: icons,
    sidebar: sidebar,
    dashboard: dashboard,
    users: users,
    missions: missions,
    coupons: coupons,
    stores: stores,
    images: images,
    avatars: avatars,
    pages: pages,
    discountcode:discountcode,
    products: products,
    options: options,
    search: search,
    analytics: analytics,
    push_notitications: push_notitications,
    ecommerce: ecommerce,
    notes: notes,
    account: account,
    campaign_codes: campaign_codes,
    receipts: receipts,
    oauth: oauth,
    news: news,
    reports: reports
}

export default en