import apiClient from '../../plugins/apiClient'
import i18n from "@/plugins/i18n";

export default {
    namespaced: true,
    state: {
        isLoading: false,
        boInvites: [],
        validInviteToken: true,
        scopes: [
            {
                value: "admin",
                label: "Amministratore"
            },
            {
                value: "operator",
                label: "Operatore CMS"
            },
            {
                value: "analyst",
                label: "Analytics"
            },
            {
                value: "store",
                label: "Operatore punto vendita"
            },
            // {
            //     value: "store_manager",
            //     label: "Manager punto vendita"
            // },
            {
                value: "cc",
                label: "Operatore Customer Care"
            }
,
            {
                value: "external",
                label: "External Oauth account"
            }
        ],
        pagination: {
            current: 1,
            total: 0,
            per_page: 15
        },
    },

    getters: {
        loadingState(state) {
            return state.isLoading
        },

        getBoInvites(state) {
            return state.boInvites
        },

        getPagination(state) {
            return state.pagination
        },

        getIsaValidToken(state) {
            return state.validInviteToken
        },

        getScopes(state) {
            return state.scopes
        }
    },

    actions: {
        fetchBoInvites({commit}) {

            console.log('STORE USERS | ACTIONS | fetchBoInvitation')

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.get('bo-invites')
                    .then(resp => {
                        commit('boInvites', resp.data.data)
                        commit('loading', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading')
                        reject(err);
                    });
            });
        },

        verifyInviteToken({commit}, payload) {
            console.log('STORE USERS | ACTIONS | verifyInviteToken payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.get('users/invite/verify/' + payload)
                    .then(resp => {
                        commit('validInviteToken', true)
                        commit('loading', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('validInviteToken', false)
                        commit('loading')
                        reject(err);
                    });
            });
        },

        storeInvitationCode({commit, dispatch}, payload) {
            console.log('STORE USERS | ACTIONS | storeInvitationCode payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.post('users/invite/new', payload)
                    .then(resp => {
                        dispatch('snackbar/showSnackBar', {message: i18n.t('users.create.messages.success'), type: 'success'}, {root: true})
                        dispatch('fetchBoInvites')
                        commit('loading', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false, {root: false})
                        dispatch('snackbar/showSnackBar', {message: i18n.t('users.create.messages.fail'), type: 'error', err: err.response, timeout: 8000}, {root: true})
                        reject(err);
                    });
            });
        },

        setLoadingStatus({commit}, payload) {
            commit('loading', payload)
        },

        reset({commit}) {
            commit('reset')
        }

    },

    mutations: {
        boInvites(state, payload)
        {
            state.boInvites = payload
        },

        validInviteToken(state, payload)
        {
            state.validInviteToken = payload
        },

        pagination(state, payload) {
            state.pagination.current = payload.current_page
            state.pagination.total = payload.last_page
            state.pagination.per_page = payload.par_page
        },

        loading(state, payload) {
            state.isLoading = payload
        },

        reset(state) {
            state.isLoading= false
            state.users= []
            state.pagination= {
                current: 1,
                total: 0,
                per_page: 15
            }
        }
    },
}
