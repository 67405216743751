<template>
  <v-row class="container--fluid">
    <v-col>
      <v-autocomplete
          v-model="result"
          :items="results"
          :loading="isLoading"
          :search-input.sync="search"
          :item-text="textSearchItem"
          item-value="id"
          cache-items
          prepend-inner-icon="mdi-magnify"
          placeholder="cerca"
          clearable
          solo-inverted
          hide-details
          @input="go"
          no-data-text="Nessun risultato"
          dense
          flat
      >

      </v-autocomplete>

    </v-col>
  </v-row>
</template>

<script>

import debounce from 'debounce'
import {mapGetters} from "vuex";

export default {
  name: "SearchUser",

  data() {
    return {
      result: null,
      searchDialog: false,
      search: ''
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'user/loadingSearchState',
      results: 'user/getSearchResults',
    }),
  },
  methods: {
    textSearchItem: item => item.user_code+' - '+item.email,
    doSearch() {
      this.searchDialog = true
      this.$store.dispatch('user/searchUser', this.search)
    },
    go(id) {
      if (id) {
        this.$router.push({
          name: 'user-profile',
          params: {
            id: id
          }
        })
      }
    },
  },
    watch: {
      search(value) {
        if (!value || value.length < 5) {
          return
        }
        debounce(this.doSearch, 500)(value, this)
      }
    }

}
</script>

<style scoped>

</style>