import apiClient from '@/plugins/apiClient'
import moment from "moment";

export default {
    namespaced: true,
    state: {
        isLoading: false,
        notes: [],
        note: {},
        pagination: {
            current: 1,
            total: 0,
            per_page: 10
        },
        filters: {
            start: moment().subtract('7', 'days').toISOString().substr(0, 10),
            end: moment().toISOString().substr(0, 10),
        }
    },

    getters: {
        loadingState(state) {
            return state.isLoading
        },

        getNotes(state) {
            return state.orders
        },

        getNote(state) {
            return state.order
        },

        getPagination(state) {
            return state.pagination
        },

        getFilters(state) {
            return state.filters
        },
    },

    actions: {
        
        fetchNotes({commit, state}, payload) {
            console.log('STORE NOTE | ACTIONS | fetchNotes payload ', payload)

            return new Promise((resolve, reject) => {
                commit('setLoading', true)

                apiClient.get('notes?page=' + state.pagination.current + '&start=' + state.filters.start + '&end=' + state.filters.end)
                    .then(resp => {
                        commit('setLoading', false, {root: false})
                        commit('setNotes', resp.data.data);
                        commit('setPagination', resp.data.meta)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('setLoading', false, {root: false})
                        reject(err);
                    });
            });
        },

        fetchNote({commit}, note_id) {

            console.log('STORE NOTE | ACTIONS | order note_id ', note_id)

            return new Promise((resolve, reject) => {
                commit('setLoading', true)

                apiClient.get('notes/' + note_id)
                    .then(resp => {
                        console.log('resp.data ', resp.data)
                        console.log('resp.data.data ', resp.data.data)
                        commit('setLoading')
                        commit('setNote', resp.data.data)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('setLoading')
                        reject(err);
                    });
            });
        },
        
        add({commit, dispatch}, payload) {
            console.log('STORE NOTE | ACTIONS | addNote payload ', payload)

            return new Promise((resolve, reject) => {
                commit('setLoading', true)

                apiClient.post('notes', payload)
                    .then(resp => {
                        console.log('resp.data ', resp.data)
                        commit('setLoading')
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('setLoading')
                        reject(err);
                    });
            });
        },

        update({commit, dispatch}, payload) {
            console.log('STORE NOTE | ACTIONS | addNote payload ', payload)

            return new Promise((resolve, reject) => {
                commit('setLoading', true)

                apiClient.patch('note/' + payload.id, payload)
                    .then(resp => {
                        console.log('resp.data ', resp.data)
                        commit('setLoading')
                        dispatch('fetchNote', resp.data.id)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('setLoading')
                        reject(err);
                    });
            });
        },

        clearNote({commit}) {
            commit('clearNote')
        },

        setFilters({commit}, payload) {
            commit('setFilters', payload)
        },

        setLoadingStatus({commit}, payload) {
            commit('setLoading', payload)
        },

        reset({commit}) {
            commit('reset')
        }

    },

    mutations: {
        setNotes(state, payload) {
            state.notes = payload
        },

        setNote(state, payload) {
            state.note = payload
        },

        setFilters(state, payload) {
            state.filters = payload
        },

        clearNote(state) {
            state.note = {}
        },

        setPagination(state, payload) {
            state.pagination.current = payload.current_page
            state.pagination.total = payload.last_page
            state.pagination.per_page = payload.par_page
        },

        setLoading(state, payload) {
            state.isLoading = payload
        },

        reset(state) {
            state.isLoading = false
            state.notes = []
            state.note = {}
            state.pagination = {
                current: 1,
                total: 0,
                per_page: 10
            }
        },

        resetFilters(state) {
            state.filters = {
                start: moment().subtract('7', 'days').toISOString().substr(0, 10),
                end: moment().toISOString().substr(0, 10),
                statuses: [],
                store: ''
            }
        }
    },
}
