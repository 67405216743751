<!-- https://blog.sqreen.com/authentication-best-practices-vue/ -->
<template>
  <center-content-template>
    <v-form
        ref="loginForm"
        v-model="valid"
        lazy-validation
        @submit="login"
    >

      <v-card
          flat
          class="py-15 elevation-0"
      >

        <v-row>
          <v-col class="text-center"
          >
            <img src="../assets/logo.svg" width="50%" alt="" class="mb-10">
            <h2 class="font-weight-medium">Accedi</h2>
            <p class="font-weight-light pb-0">Inserisci le tue credenziali per continuare</p>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="px-15"
          >
            <v-alert
                v-if="tokenExpired"
                outlined
                type="error"
                prominent
            >
              {{ $t('commons.token-expired') }}
            </v-alert>

            <v-alert
                v-if="error"
                outlined
                type="error"
                prominent
            >
              {{ error }}
            </v-alert>

            <v-text-field
                :label="$t('auth.login-form.email')"
                name="login"
                prepend-icon="mdi-account"
                type="text"
                v-model="email"
                required
                :rules="requiredRules"
            ></v-text-field>

            <v-text-field
                id="password"
                :label="$t('auth.login-form.password')"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                v-model="password"
                required
                :rules="requiredRules"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center"
          >
            <v-btn type="submit" :loading="sending" large color="primary">
              {{ $t('auth.login-form.login-button') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>

    </v-form>

    <divider></divider>
    <v-row>
      <v-col class="d-flex justify-center">
        <v-btn color="success" outlined rounded small :to="{name: 'forgotPassowrd'}">
          {{ $t('account.password.forgot.btn_text') }}
        </v-btn>
      </v-col>
    </v-row>
  </center-content-template>

</template>

<script>

import CenterContentTemplate from "@/views/Layout/_partials/CenterContentTemplate";

export default {
  name: "Login",

  components: {
    CenterContentTemplate
  },

  data() {
    return {
      valid: true,
      tokenExpired: false,
      sending: false,
      email: '',
      password: '',
      requiredRules: [
        v => !!v || 'Il campo è obbligatorio',
      ],
      error: '',
    }
  },

  mounted() {
  },

  methods: {
    async login(event) {
      event.preventDefault()

      const valid = await this.$refs.loginForm.validate()

      // VALIDATION
      if (!valid)
        return false;

      if (valid === true) {
        this.sending = true
        const {email, password} = this
        this.$store.dispatch('auth/login', {email, password})
            .then(() => {
              this.$router.push({name: 'dashboard'})
            })
            .catch(() => {
              this.sending = false
              this.error = 'Credenziali non valide'
            })
      }
    }
  }
}
</script>

<style scoped>

</style>
