import apiClient from '../../plugins/apiClient'
import i18n from "@/plugins/i18n";

export default {
    namespaced: true,
    state: {
        isLoading: false,
        codes: [],
        code: {},
        links: {},
        pagination: {
            current: 1,
            total: 0,
            per_page: 15
        },
    },

    getters: {
        loadingState(state) {
            return state.isLoading
        },

        getCodes(state) {
          return state.codes
        },

        getCode(state) {
            return state.code
        },

        getPagination(state) {
            return state.pagination
        },

        getLinks(state) {
            return state.links
        }
    },

    actions: {
        fetchCodes({commit}, payload) {
            commit('loading', true)
            return new Promise((resolve, reject) => {

                console.log('payload ' , payload)
                let url = (payload && payload.link) ? payload.link : 'codes/ecommerce?page=1'
                let find = (payload.find) ? '&' + Object.entries(payload.find).map(e => e.join('=')).join('&') : ''

                apiClient.get(url + find)
                    .then(resp => {
                        commit('loading', false)
                        commit('codes', resp.data.data)
                        commit('pagination', resp.data.meta)
                        commit('links', resp.data.links)
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('loading', false)
                        reject(err)
                    })
            })
        },

        findByValue({commit}, payload) {
            commit('loading', true)
            return new Promise((resolve, reject) => {
                apiClient.get('codes/ecommerce/find-by-value?value=' + payload.value)
                    .then(resp => {
                        commit('loading', false)
                        commit('code', resp.data.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('loading', false)
                        reject(err)
                    })
            })
        },

        burn({commit, dispatch}, payload) {
            commit('loading', true)
            return new Promise((resolve, reject) => {

                apiClient.patch('codes/ecommerce/burn/' + payload.id)
                    .then(resp => {
                        dispatch('fetchCodes', {link: '', value: ''})
                        dispatch('snackbar/showSnackBar', {message: i18n.t('coupons.ecommerce_code.messages.burned.success'), type: 'success'}, {root: true})
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('loading', false)
                        dispatch('snackbar/showSnackBar', {message: i18n.t('coupons.ecommerce_code.messages.burned.fail'), type: 'error', err: err.response, timeout: 8000}, {root: true})
                        reject(err)
                    })
            })
        },

        generate({commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.post('codes/ecommerce/generate', payload)
                    .then(resp => {
                        commit('loading', false)
                        dispatch('snackbar/showSnackBar', {message: i18n.t('coupons.codes.messages.generate.success'), type: 'success'}, {root: true})
                        // @todo check avanzamento
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false, {root: false})
                        dispatch('snackbar/showSnackBar', {message: i18n.t('coupons.codes.messages.create.fail'), type: 'error', err: err.response, timeout: 8000}, {root: true})
                        reject(err);
                    });
            });
        },

        updatethreshold({commit, dispatch}, payload) {
            console.log('STORE threshold | ACTIONS | updatethreshold payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.patch('codes/' + payload.id, payload)
                    .then(resp => {
                        dispatch('snackbar/showSnackBar', {message: i18n.t('coupons.codes.messages.update.success'), type: 'success'}, {root: true})
                        dispatch('fetchcodes')
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false)
                        dispatch('snackbar/showSnackBar', {message: i18n.t('coupons.codes.messages.update.fail'), type: 'error', err: err.response, timeout: 8000}, {root: true})
                        reject(err);
                    });
            });
        },

        setLoadingStatus({commit}, payload) {
            commit('loading', payload)
        },
    },

    mutations: {
        codes(state, payload) {
            state.codes = payload
        },

        code(state, payload) {
            state.code = payload
        },

        pagination(state, payload) {
            state.pagination.current = payload.current_page
            state.pagination.total = payload.last_page
            state.pagination.per_page = payload.par_page
        },

        loading(state, payload) {
            state.isLoading = payload
        },

        links(state, payload) {
            console.log('set links payload ' , payload)
            state.links = payload
        },

        reset(state) {
            state.isLoading= false
            state.coupons= []
            state.pagination= {
                current: 1,
                total: 0,
                per_page: 15
            }
        }
    },
}
