import apiClient from '@/plugins/apiClient'
import i18n from "@/plugins/i18n";
import moment from "moment";

export default {
    namespaced: true,
    state: {
        isLoading: false,
        isLoadingDetails: false,
        isPatching: false,
        isDeleting: false,
        isLoadingNotes: false,
        types: [],
        order: {},
        orderNotes: [],
        statuses: [],
        stores: [],
        search: {
            loading: false,
            results: []
        },
    },

    getters: {
        loadingDetails(state) {
            return state.isLoadingDetails
        },

        isPatching(state) {
            return state.isPatching
        },

        isDeleting(state) {
            return state.isDeleting
        },

        isLoadingNotes(state) {
            return state.isLoadingNotes
        },

        getOrders(state) {
            return state.orders
        },

        getOrder(state) {
            return state.order
        },

        getOrderNotes(state) {
            return state.orderNotes
        },

        getStatuses(state) {
            return state.statuses
        },

        getSearchResults(state) {
            return state.search.results
        },

        loadingSearchState(state) {
            return state.search.loading
        },

        getPagination(state) {
            return state.pagination
        },

        getFilters(state) {
            return state.filters
        },

        getStores(state) {
            return state.stores
        },
    },

    actions: {

        fetchOrderStatuses({commit}, payload) {
            console.log('STORE ECOMMERCE | ACTIONS | fetchOrders payload ', payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.get('ecommerce/orders/statuses')
                    .then(resp => {
                        commit('loading', false, {root: false})
                        commit('statuses', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false, {root: false})
                        reject(err);
                    });
            });
        },

        fetchEnabledStores({commit}, payload) {
            console.log('STORE ECOMMERCE | ACTIONS | fetchEnabledStores payload ', payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.get('ecommerce/enabled-stores')
                    .then(resp => {
                        commit('loading', false)
                        commit('stores', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false)
                        reject(err);
                    });
            });
        },

        fetchOrder({commit, dispatch}, order_id) {

            console.log('STORE ECOMMERCE | ACTIONS | order order_id ', order_id)

            return new Promise((resolve, reject) => {
                commit('loadingDetails', true)

                apiClient.get('ecommerce/orders/' + order_id)
                    .then(resp => {
                        console.log('resp.data ', resp.data)
                        console.log('resp.data.data ', resp.data.data)
                        commit('loadingDetails', false)
                        commit('order', resp.data.data)
                        dispatch('fetchOrderNotes', order_id)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loadingDetails', false)
                        reject(err);
                    });
            });
        },

        fetchOrderNotes({commit}, order_id) {

            console.log('STORE ECOMMERCE | ACTIONS | order order_id ', order_id)

            return new Promise((resolve, reject) => {
                commit('loadingNotes', true)

                apiClient.get('ecommerce/orders/' + order_id + '/notes')
                    .then(resp => {
                        console.log('resp.data ', resp.data)
                        console.log('resp.data.data ', resp.data.data)
                        commit('loadingNotes', false)
                        commit('notes', resp.data.data)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loadingNotes', false)
                        reject(err);
                    });
            });
        },

        searchOrder({commit}, code) {

            console.log('STORE ECOMMERCE | ACTIONS | searchOrder code ', code)

            return new Promise((resolve, reject) => {
                commit('searching', true)

                apiClient.get('ecommerce/orders/find-by-code?code=' + code)
                    .then(resp => {
                        commit('searchResults', resp.data)
                        commit('searching', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('searching')
                        reject(err);
                    });
            });
        },

        clearSearch({commit}) {
            commit('clearSearch')
        },

        clearOrder({commit}) {
            commit('clearOrder')
        },

        updateOrder({commit, dispatch}, payload) {
            console.log('STORE ECOMMERCE | ACTIONS | updateOrder payload ', payload)

            return new Promise((resolve, reject) => {
                commit('patching', true)

                apiClient.patch('ecommerce/orders/' + payload.id, {status: payload.status})
                    .then(resp => {
                        commit('patching', false)
                        dispatch('snackbar/showSnackBar', {
                            message: i18n.t('ecommerce.orders.messages.update.success'),
                            type: 'success'
                        }, {root: true})
                        dispatch('fetchOrder', payload.id)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('patching', false)
                        dispatch('snackbar/showSnackBar', {
                            message: i18n.t('ecommerce.orders.messages.update.fail'),
                            type: 'error',
                            err: err.response,
                            timeout: 8000
                        }, {root: true})
                        reject(err);
                    });
            });
        },

        deleteOrder({commit, dispatch}, order_id) {
            console.log('STORE ECOMMERCE | ACTIONS | deleteOrder payload ', order_id)

            return new Promise((resolve, reject) => {
                commit('deleting', true)

                apiClient.delete('ecommerce/orders/' + order_id)
                    .then(resp => {
                        commit('deleting', false)
                        dispatch('snackbar/showSnackBar', {
                            message: i18n.t('ecommerce.orders.messages.delete.success'),
                            type: 'success'
                        }, {root: true})
                        dispatch('fetchOrder', order_id)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('deleting', false)
                        dispatch('snackbar/showSnackBar', {
                            message: i18n.t('ecommerce.orders.messages.delete.fail'),
                            type: 'error',
                            err: err.response,
                            timeout: 8000
                        }, {root: true})
                        reject(err);
                    });
            });
        },

        setFilters({commit}, payload) {
            commit('filters', payload)
        },

        setStatusesFilter({commit}, payload) {
            commit('filterStatusess', payload)
        },

        setStores({commit}, payload) {
            commit('stores', payload)
        },

        setLoadingStatus({commit}, payload) {
            commit('loading', payload)
        },

        reset({commit}) {
            commit('reset')
            commit('resetFilters')
        }

    },

    mutations: {
        types(state, payload) {
            state.types = payload
        },

        statuses(state, payload) {
            state.statuses = payload
        },

        ecommerceValues(state, payload) {
            console.log('payload ', payload.data)
            state.ecommerceValues = payload.data.split(',')
        },

        parentOrders(state, payload) {
            state.parentOrders = payload
        },

        searchResults(state, payload) {
            state.search.results = payload
        },

        searching(state, payload) {
            state.search.loading = payload
        },

        filters(state, payload) {
            state.filters = payload
        },

        filterStatusess(state, payload) {
            state.filters.statuses = payload
        },

        stores(state, payload) {
            state.stores = payload
        },

        parentOrderCountry(state, payload) {
            state.parentOrdersCountry = payload
        },

        order(state, payload) {
            console.log('order ', payload)
            state.order = payload
        },

        notes(state, payload) {
            state.orderNotes = payload
        },

        clearOrder(state) {
            state.order = {}
        },

        clearSearch(state) {
            state.search = {
                loading: false,
                results: []
            }
        },

        loading(state, payload) {
            state.isLoading = payload
        },

        patching(state, payload) {
            state.isPatching = payload
        },

        deleting(state, payload) {
            state.isDeleting = payload
        },

        loadingDetails(state, payload) {
            state.isLoadingDetails = payload
        },

        loadingNotes(state, payload) {
            state.isLoadingNotes = payload
        },

        reset(state) {
            state.isLoading = false
            state.orders = []
            state.pagination = {
                current: 1,
                total: 0,
                per_page: 15
            }
        },

        resetFilters(state) {
            state.filters = {
                start: moment().subtract('7', 'days').toISOString().substr(0, 10),
                end: moment().toISOString().substr(0, 10),
                statuses: [],
                store: ''
            }
        }
    },
}
