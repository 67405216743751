import apiClient from '../../plugins/apiClient'
import i18n from "@/plugins/i18n";

export default {
    namespaced: true,
    state: {
        isLoading: false,
        discountCodes: [],
        page: {},
        pagination: {
            current: 1,
            total: 0,
            per_page: 15
        },
    },

    getters: {
        loadingState(state) {
            return state.isLoading
        },

        getDiscountCodes(state) {
            return state.discountCodes
        },

        getPagination(state) {
            return state.pagination
        },
    },

    actions: {
        // get pages
        fetchDiscountCodes({commit, state}, payload) {

            console.log('STORE DISCOUNTCODES | ACTIONS | pages payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.get('discount_codes?page=' + state.pagination.current)
                    .then(resp => {
                        commit('discountCodes', resp.data.data);
                        commit('pagination', resp.data.meta)
                        commit('loading', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false, {root: false})
                        reject(err);
                    });
            });
        },

        store({commit, dispatch}, payload) {
            console.log('STORE DISCOUNTCODES | ACTIONS | store payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.post('discount_codes/create', payload)
                    .then(resp => {
                        commit('discount_codes', resp.data.data)
                        dispatch('snackbar/showSnackBar', {message: i18n.t('pages.create.messages.success'), type: 'success'}, {root: true})
                        dispatch('fetchDiscountCodes')
                        commit('loading', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false, {root: false})
                        dispatch('snackbar/showSnackBar', {message: i18n.t('pages.create.messages.fail'), type: 'error', err: err.response, timeout: 8000}, {root: true})
                        reject(err);
                    });
            });
        },

        update({commit, dispatch}, payload) {
            console.log('STORE DISCOUNTCODES | ACTIONS | update payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.patch('discount_codes/update/' + payload.id, payload.discountcode)
                    .then(resp => {
                        commit('discountCodes', resp.data.data)
                        dispatch('snackbar/showSnackBar', {message: i18n.t('discountcode.create.messages.success'), type: 'success'}, {root: true})
                        dispatch('fetchDiscountCodes')
                        commit('loading', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false, {root: false})
                        dispatch('snackbar/showSnackBar', {message: i18n.t('discountcode.create.messages.fail'), type: 'error', err: err.response, timeout: 8000}, {root: true})
                        reject(err);
                    });
            });
        },

        setLoadingStatus({commit}, payload) {
            commit('loading', payload)
        },

        reset({commit}) {
            commit('reset')
        }

    },

    mutations: {
        discountCodes(state, payload) {
            state.discountCodes = payload
        },

        pagination(state, payload) {
            state.pagination.current = payload.current_page
            state.pagination.total = payload.last_page
            state.pagination.per_page = payload.par_page
        },

        loading(state, payload) {
            state.isLoading = payload
        },

        reset(state) {
            state.isLoading= false
            state.discountCodes = []
            state.pagination= {
                current: 1,
                total: 0,
                per_page: 15
            }
        }
    },
}
