import apiClient from '../../plugins/apiClient'
import i18n from "@/plugins/i18n";

export default {
    namespaced: true,
    state: {
        isLoading: false,
        isSearching: false,
        products: [],
        product: {},
        locales: ["it","en"],
        pagination: {
            current: 1,
            total: 0,
            per_page: 15
        },
    },

    getters: {
        loadingState(state) {
            return state.isLoading
        },

        searchingState(state) {
            return state.isSearching
        },

        getLocales(state) {
            return state.locales
        },

        getProducts(state) {
            return state.products
        },

        getProduct(state) {
            return state.product
        },

        getProductById: (state) => (id) => {
            console.log('STORE PRODUCT | GETTERS | getProductById ' , id)
            console.log('STORE PRODUCT | GETTERS | products ' , state.products)
            return state.products.find(product => product.id === id)
        },

        getPagination(state) {
            return state.pagination
        },
    },

    actions: {
        // get products
        fetchProducts({commit, state, rootGetters}, payload) {

            console.log('STORE PRODUCTS | ACTIONS | fetchProducts payload ' , payload)

            if (rootGetters["auth/canManageAppData"]) {
                return new Promise((resolve, reject) => {
                    commit('loading', true)

                    console.log('payload ' , payload)
                    let find = (payload && Object.prototype.hasOwnProperty.call(payload, 'find')) ? '&' + Object.entries(payload.find).map(e => e.join('=')).join('&') : ''

                    apiClient.get('products?page=' + state.pagination.current  + find)
                        .then(resp => {
                            commit('loading', false, {root: false})
                            commit('products', resp.data.data);
                            commit('pagination', resp.data.meta)
                            resolve(resp);
                        })
                        .catch(err => {
                            commit('loading', false, {root: false})
                            reject(err);
                        });
                });
            }
        },

        fetchProduct({commit}, product_id) {

            console.log('STORE PRODUCTS | ACTIONS | product product_id ' , product_id)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.get('products/' + product_id)
                    .then(resp => {
                        commit('loading')
                        commit('product', resp.data)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading')
                        reject(err);
                    });
            });
        },

        findProduct({commit}, serchText) {
            console.log('STORE PRODUCTS | ACTIONS | find by serchText ' , serchText)

            return new Promise((resolve, reject) => {
                commit('isSearching', true)

                apiClient.get('products/find?q=' + serchText)
                    .then(resp => {
                        commit('isSearching', false)
                        commit('product', resp.data.data)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('isSearching', false)
                        reject(err);
                    });
            });
        },

        store({commit, dispatch}, payload) {
            console.log('STORE PRODUCTS | ACTIONS | store payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.post('products', payload)
                    .then(resp => {
                        commit('loading', false, {root: false})
                        commit('product', resp.data.product)
                        dispatch('snackbar/showSnackBar', {message: i18n.t('products.create.messages.success'), type: 'success'}, {root: true})
                        dispatch('fetchProducts')
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false, {root: false})
                        dispatch('snackbar/showSnackBar', {message: i18n.t('products.create.messages.fail'), type: 'error', err: err.response}, {root: true})
                        reject(err);
                    });
            });
        },

        update({commit, dispatch}, payload) {
            console.log('STORE PRODUCTS | ACTIONS | store payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.patch('products/' + payload.id, payload)
                    .then(resp => {
                        commit('loading', false, {root: false})
                        commit('product', resp.data.product)
                        dispatch('snackbar/showSnackBar', {message: i18n.t('products.update.messages.success'), type: 'success'}, {root: true})
                        dispatch('fetchProducts')
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false, {root: false})
                        dispatch('snackbar/showSnackBar', {message: i18n.t('products.update.messages.fail'), type: 'error', err: err.response, timeout: 8000}, {root: true})
                        reject(err);
                    });
            });
        },

        setLoadingStatus({commit}, payload) {
            commit('loading', payload)
        },

        resetProduct({commit}) {
            commit('product', {})
        },

        reset({commit}) {
            commit('reset')
        }

    },

    mutations: {
        products(state, payload) {
            state.products = payload
        },

        product(state, payload) {
            state.product = payload
        },

        pagination(state, payload) {
            state.pagination.current = payload.current_page
            state.pagination.total = payload.last_page
            state.pagination.per_page = payload.par_page
        },

        loading(state, payload) {
            state.isLoading = payload
        },

        isSearching(state, payload) {
            state.isSearching = payload
        },

        reset(state) {
            state.isLoading= false
            state.products= []
            state.pagination= {
                current: 1,
                total: 0,
                per_page: 15
            }
        }
    },
}
