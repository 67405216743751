import Vue from "vue";
import Vuex from "vuex";
import consts from "@/store/modules/consts";
import user from "./modules/user";
import account from '@/store/modules/account';
import point from "@/store/modules/point";
import auth from "./modules/auth";
import mission from "@/store/modules/mission";
import coupon from "@/store/modules/coupon";
import store from "@/store/modules/store";
import image from "@/store/modules/image";
import avatar from "@/store/modules/avatar";
import snackbar from "./modules/snackbar";
import page from "@/store/modules/page";
import news from "@/store/modules/news";
import boInvite from "@/store/modules/boInvite";
import qrcode from "@/store/modules/qrcode";
import product from "@/store/modules/product";
import couponThreshold from "@/store/modules/couponThreshold";
import venchiOption from "@/store/modules/venchiOption";
import couponEcommerceCode from "@/store/modules/couponEcommerceCode";
import pushNotification from "@/store/modules/pushNotification";

import analyticsRange from "@/store/modules/analytics/dateRanges"
import analyticsUser from "@/store/modules/analytics/users";
import analyticsCoupon from "@/store/modules/analytics/coupons";
import analyticsReceipt from "@/store/modules/analytics/receipts";
import analyticsMission from "@/store/modules/analytics/missions";

import adminBillModifiers from "@/store/modules/adminBillModifiers";
import overlays from "@/store/modules/overlays";
import exports from "@/store/modules/exports";

import ecommerce from "@/store/modules/ecommerce/ecommerce";
import note from '@/store/modules/note';
import discountCode from "@/store/modules/discountCode";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
    modules: {
        consts,
        user,
        account,
        point,
        auth,
        mission,
        coupon,
        snackbar,
        store,
        image,
        avatar,
        page,
        news,
        boInvite,
        couponThreshold,
        qrcode,
        product,
        venchiOption,
        couponEcommerceCode,
        pushNotification,
        analyticsRange,
        analyticsUser,
        analyticsCoupon,
        analyticsReceipt,
        analyticsMission,
        ecommerce: ecommerce,
        note: note,
        discountCode,
        adminBillModifiers,
        overlays,
        exports
    },
    // strict: debug
});
