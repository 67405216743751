import apiClient from '../../../plugins/apiClient'

export default {
    namespaced: true,
    state: {
        isLoading: false,
        statsData: {}
    },

    getters: {
        loadingState(state) {
            return state.isLoading
        },

        getStatsData(state) {
            return state.statsData
        },

        getStatsRange(state) {
            return state.statsRange
        }
    },

    actions: {
        fetchStats({commit, rootState}, payload) {

            console.log('STORE ANALYTICS | ACTIONS | fetchData payload ', payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.get('analytics/missions/daily?start=' + rootState.analyticsRange.statsRange.start + '&end=' + rootState.analyticsRange.statsRange.end + '&country=' + rootState.analyticsRange.statsRange.country)
                    .then(resp => {
                        commit('loading', false, {root: false})
                        commit('statsData', {
                            stats: payload,
                            data: resp.data
                        });
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false, {root: false})
                        reject(err);
                    });
            });
        }
    },

    mutations: {
        statsData(state, payload) {
            state.statsData= payload.data
        },

        loading(state, payload) {
            state.isLoading = payload
        }
    }
}
