import apiClient from '../../plugins/apiClient'

export default {
    namespaced: true,
    state: {
        isLoading: false,
        qrcode: ''
    },

    getters: {
        getQrCode(state) {
            return state.qrcode
        }
    },

    actions: {
        makeQrCode({commit, state}, payload) {

            console.log('STORE QRCODE | ACTIONS | makeQrCode payload ', payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.post('qrcodes', payload)
                    .then(resp => {
                        commit('loading', false)
                        commit('qrcoe', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false)
                        reject(err);
                    });
            });

        }
    },

    mutations: {
        qrcode(state, payload) {
            state.qrcode = payload
        },

    }
}