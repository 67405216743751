import apiClient from '../../plugins/apiClient'
import i18n from "@/plugins/i18n";

export default {
    namespaced: true,
    state: {
        isLoading: false,
        images: [],
        image: {},
        pagination: {
            current: 1,
            total: 0,
            per_page: 15
        },
        defaulPagination: {
            current: 1,
            total: 0,
            per_page: 15
        },
    },

    getters: {
        loadingState(state) {
            return state.isLoading
        },

        getImages(state) {
            return state.images
        },

        getImage(state) {
            return state.image
        },

        getImageById: (state) => (id) => {
            console.log('STORE IMAGE | GETTERS | getImageById ' , id)
            console.log('STORE IMAGE | GETTERS | images ' , state.images)
            return state.images.find(image => image.id === id)
        },

        getPagination(state) {
            return state.pagination
        },
    },

    actions: {
        // get images
        fetchImages({commit, state, rootGetters}, payload) {

            console.log('STORE IMAGES | ACTIONS | images payload ' , payload)

            if (rootGetters["auth/canManageAppData"]) {
                return new Promise((resolve, reject) => {
                    commit('loading', true)

                    apiClient.get('images?page=' + state.pagination.current)
                        .then(resp => {
                            commit('loading', false)
                            commit('images', resp.data.data);
                            commit('pagination', resp.data.meta)
                            resolve(resp);
                        })
                        .catch(err => {
                            commit('loading', false)
                            reject(err);
                        });
                });
            }
        },

        setImage({commit}, image_id) {

            console.log('STORE IMAGES | ACTIONS | image image_id ' , image_id)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.get('images/' + image_id)
                    .then(resp => {
                        commit('loading', false)
                        commit('image', resp.data.image)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false)
                        reject(err);
                    });
            });
        },

        store({commit, dispatch}, payload) {
            console.log('STORE IMAGES | ACTIONS | store payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.post('images', payload)
                    .then(resp => {
                        commit('loading', false, {root: false})
                        commit('image', resp.data.image)
                        dispatch('snackbar/showSnackBar', {message: i18n.t('images.create.messages.success'), type: 'success'}, {root: true})
                        dispatch('fetchImages')
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false, {root: false})
                        dispatch('snackbar/showSnackBar', {message: i18n.t('images.create.messages.fail'), type: 'error', err: err.response, timeout: 8000}, {root: true})
                        reject(err);
                    });
            });
        },

        setLoadingStatus({commit}, payload) {
            commit('loading', payload)
        },

        resetPagination({commit}) {
            commit('resetPagination')
        },

        reset({commit}) {
            commit('reset')
            commit('resetPagination')
        }

    },

    mutations: {
        images(state, payload) {
            state.images = payload
        },

        image(state, payload) {
            state.image = payload
        },

        pagination(state, payload) {
            state.pagination.current = payload.current_page
            state.pagination.total = payload.last_page
            state.pagination.per_page = payload.par_page
        },

        loading(state, payload) {
            state.isLoading = payload
        },

        resetPagination(state) {
            state.pagination= {
                current: 1,
                total: 0,
                per_page: 15
            }
        },

        reset(state) {
            state.isLoading= false
            state.images= []
        }
    },
}
