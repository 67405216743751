export default {
    namespaced: true,
    state: {
        countries: [
            {
                "name": "Italia",
                "value": "it"
            },
            {
                "name": "Inghilterra",
                "value": "uk"
            },
            {
                "name": "United States",
                "value": "us"
            }
        ],
        languages: ['it', 'en', 'us']
    },

    getters: {
        getCountries(state) {
            return state.countries
        },
        getLanguages(state) {
            return state.languages
        },
    }
}