import apiClient from '../../plugins/apiClient'
import i18n from "@/plugins/i18n";
const querystring = require('querystring');

export default {
    namespaced: true,
    state: {
        isLoading: false,
        fetching: false,
        filters: [],
        types: [],
        missions: [],
        parentMissions: [],
        parentMissionsCountry: '',
        mission: {},
        pagination: {
            current: 1,
            total: 0,
            per_page: 15
        },
    },

    getters: {
        loadingState(state) {
            return state.isLoading
        },

        fetchingState(state) {
            return state.fetching
        },

        getTypes(state) {
          return state.types
        },

        getFilters(state) {
            return state.filters
        },

        getMissions(state) {
            return state.missions
        },

        getParentMissions(state) {
            return state.parentMissions
        },

        getParentMissionCountry(state) {
            return state.parentMissionsCountry
        },

        getMission(state) {
            return state.mission
        },

        getMissionContent(state) {
            return state.mission.content
        },

        getMissionById: (state) => (id) => {
            console.log('STORE MISSION | GETTERS | getMissionById ' , id)
            console.log('STORE MISSION | GETTERS | missions ' , state.missions)
            return state.missions.find(mission => mission.id === id)
        },

        getPagination(state) {
            return state.pagination
        },
    },

    actions: {
        setTypes({commit}) {
            console.log('STORE MISSIONS | ACTIONS | setTypes')
            return new Promise((resolve, reject) => {
                apiClient.get('missions/types')
                    .then(resp => {
                        commit('types', resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        // get missions
        fetchMissions({commit, state}, payload) {
            console.log('STORE MISSIONS | ACTIONS | fetchMissions payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('fetching', true)

                apiClient.get('missions?page=' + state.pagination.current  + '&' + querystring.stringify(state.filters))
                    .then(resp => {
                        commit('fetching', false)
                        commit('missions', resp.data.data);
                        commit('pagination', resp.data)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('fetching', false)
                        reject(err);
                    });
            });
        },

        setFilters({commit}, payload) {
            commit('setFilters', payload)
        },

        setParentMissions({commit}, country) {
            console.log('STORE MISSIONS | ACTIONS | setParentMissions country ' , country)

            return new Promise((resolve, reject) => {
                commit('fetching', true)

                apiClient.get('missions/parents/' + country)
                    .then(resp => {
                        commit('parentMissions', resp.data);
                        commit('parentMissionCountry', country)
                        commit('fetching', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('fetching', false)
                        reject(err);
                    });
            });
        },

        setMission({commit}, mission_id) {

            console.log('STORE MISSIONS | ACTIONS | mission mission_id ' , mission_id)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.get('missions/' + mission_id)
                    .then(resp => {
                        console.log('resp.data ' , resp.data)
                        console.log('resp.data.data ' , resp.data.data)
                        commit('loading')
                        commit('mission', resp.data.data)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading')
                        reject(err);
                    });
            });
        },

        clearMission({commit}) {
          commit('clearMission')
        },

        storeMission({commit, dispatch}, payload) {
            console.log('STORE MISSIONS | ACTIONS | storeMission payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.post('missions', payload)
                    .then(resp => {
                        commit('mission', resp.data.mission)
                        dispatch('snackbar/showSnackBar', {message: i18n.t('missions.messages.create.success'), type: 'success'}, {root: true})
                        dispatch('fetchMissions')
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false, {root: false})
                        dispatch('snackbar/showSnackBar', {message: i18n.t('missions.messages.create.fail'), type: 'error', err: err.response}, {root: true})
                        reject(err);
                    });
            });
        },

        updateMission({commit, dispatch}, payload) {
            console.log('STORE MISSIONS | ACTIONS | updateMission payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.patch('missions/' + payload.id, payload)
                    .then(resp => {
                        commit('loading', false)
                        dispatch('snackbar/showSnackBar', {message: i18n.t('missions.messages.update.success'), type: 'success'}, {root: true})
                        dispatch('fetchMissions')
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false)
                        dispatch('snackbar/showSnackBar', {message: i18n.t('missions.messages.update.fail'), type: 'error', err: err.response, timeout: 8000}, {root: true})
                        reject(err);
                    });
            });
        },

        setLoadingStatus({commit}, payload) {
            commit('loading', payload)
        },

        reset({commit}) {
            commit('reset')
        }

    },

    mutations: {
        types(state, payload) {
            state.types = payload
        },

        missions(state, payload) {
            state.missions = payload
        },

        parentMissions(state, payload) {
            state.parentMissions = payload
        },

        parentMissionCountry(state, payload) {
            state.parentMissionsCountry = payload
        },

        mission(state, payload) {
            console.log('mission ' , payload)
            state.mission = payload
        },

        clearMission(state) {
            state.mission = {}
        },

        pagination(state, payload) {
            state.pagination.current = payload.current_page
            state.pagination.total = payload.last_page
            state.pagination.per_page = payload.par_page
        },

        loading(state, payload) {
            state.isLoading = payload
        },

        fetching(state, payload) {
            state.fetching = payload
        },

        setFilters(state, payload) {
            state.filters = payload
        },

        reset(state) {
            state.isLoading= false
            state.missions= []
            state.pagination= {
                current: 1,
                total: 0,
                per_page: 15
            }
        }
    },
}
