import apiClient from '../../plugins/apiClient'
import i18n from "@/plugins/i18n";

export default {
    namespaced: true,
    state: {
        isLoading: false,
        pushNotifications: [],
        pushNotification: {},
        pagination: {
            current: 1,
            total: 0,
            per_page: 10
        },
        message: null
    },

    getters: {
        loadingState(state) {
            return state.isLoading
        },
        message(state) {
            return state.message
        },
        getPushNotifications(state) {
            return state.pushNotifications
        },

        getPushNotification(state) {
            return state.pushNotification
        },

        getPagination(state) {
            return state.pagination
        },
    },

    actions: {
        fetchAll({commit, state}, payload) {

            console.log('STORE PUSHNOTIFICATIONS | ACTIONS | fetch | payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.get('push-notifications?page=' + state.pagination.current)
                    .then(resp => {
                        commit('loading', false, {root: false})
                        commit('pushNotifications', resp.data.data);
                        commit('pagination', resp.data.meta)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false, {root: false})
                        reject(err);
                    });
            });
        },

        fetchSingle({commit}, push_id) {

            console.log('STORE PUSHNOTIFICATIONS | ACTIONS | fetchSingle | push_id ' , push_id)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.get('push-notifications/' + push_id)
                    .then(resp => {
                        commit('loading')
                        commit('pushNotification', resp.data.data)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading')
                        reject(err);
                    });
            });
        },

        store({commit, dispatch}, payload) {
            console.log('STORE PUSHNOTIFICATIONS | ACTIONS | store payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.post('push-notifications/create', payload)
                    .then(resp => {
                        commit('loading', false, {root: false})
                        commit('push_notifications', resp.data.push_notification)
                        dispatch('snackbar/showSnackBar', {message: i18n.t('push_notifications.create.messages.success'), type: 'success'}, {root: true})
                        dispatch('setpush_notifications')
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false, {root: false})
                        dispatch('snackbar/showSnackBar', {message: i18n.t('push_notifications.create.messages.fail'), type: 'error', err: err.response, timeout: 8000}, {root: true})
                        reject(err);
                    });
            });
        },

        update({commit, dispatch}, payload) {
            console.log('STORE PUSHNOTIFICATIONS | ACTIONS | store payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.patch('push-notifications/update/' + payload.id, payload)
                    .then(resp => {
                        commit('loading', false, {root: false})
                        commit('push_notifications', resp.data.push_notifications)
                        dispatch('snackbar/showSnackBar', {message: i18n.t('push_notifications.update.messages.success'), type: 'success'}, {root: true})
                        dispatch('setpush_notifications')
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false, {root: false})
                        dispatch('snackbar/showSnackBar', {message: i18n.t('push_notifications.update.messages.fail'), type: 'error', err: err.response, timeout: 8000}, {root: true})
                        reject(err);
                    });
            });
        },

        send({commit, dispatch}, payload) {
            console.log('SEND PUSHNOTIFICATIONS | ACTIONS | send payload ', payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.post('push-notifications/send/' + payload.push_id, payload)
                    .then(resp => {
                        commit('loading', false, {root: false})
                        commit('message', resp.data)
                        dispatch('snackbar/showSnackBar', {message: i18n.t('push_notifications.update.messages.success'), type: 'success'}, {root: true})
                        // dispatch('setpush_notifications')
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false, {root: false})
                        dispatch('snackbar/showSnackBar', {message: i18n.t('push_notifications.update.messages.fail'), type: 'error', err: err.response, timeout: 8000}, {root: true})
                        reject(err);
                    });
            });
        },

        setLoadingStatus({commit}, payload) {
            commit('loading', payload)
        },

        reset({commit}) {
            commit('reset')
        }

    },

    mutations: {
        pushNotifications(state, payload) {
            state.pushNotifications = payload
        },

        pushNotification(state, payload) {
            state.pushNotification = payload
        },

        pagination(state, payload) {
            state.pagination.current = payload.current_page
            state.pagination.total = payload.last_page
            state.pagination.per_page = payload.par_page
        },

        loading(state, payload) {
            state.isLoading = payload
        },

        message(state,payload){
            state.message = payload.message
        },

        reset(state) {
            state.isLoading= false
            state.pushNotifications= []
            state.pushNotification= {}
            state.pagination= {
                current: 1,
                total: 0,
                per_page: 10
            }
        }
    },
}
