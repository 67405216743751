import Vue from 'vue';
import VuetifyConfirm from 'vuetify-confirm'
import i18n from "@/plugins/i18n";

import Vuetify from 'vuetify/lib';
// import plugin
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'
// Vuetify's CSS styles
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify);

// Vuetify Object (as described in the Vuetify 2 documentation)
// const vuetify = new Vuetify()

import it from 'vuetify/src/locale/it.ts'
import '@/sass/app.scss'


const vuetifyOpts = {
    rtl: false,
    locales: { it },
    theme: {
        dark: false,
        themes: {
            dark: {
                primary: '#21cff3',
                accent: '#FF4081',
                secondary: '#ffe18d',
                success: '#4CAF50',
                info: '#2196F3',
                warning: '#FB8C00',
                error: '#FF5252'
            },
            light: {
                primary: '#3B190C',
                accent: '#e91e63',
                secondary: '#30b1dc',
                success: '#4CAF50',
                info: '#2196F3',
                warning: '#FB8C00',
                error: '#FF5252'
            }
        }
    }
}


const vuetify = new Vuetify(vuetifyOpts);
Vue.use(VuetifyConfirm, { vuetify,
    buttonTrueText: i18n.t('commons.actions.confirm'),
    buttonFalseText: i18n.t('commons.actions.cancel'),
    color: 'primary',
    width: 350,
    property: '$confirm'
})

Vue.use(TiptapVuetifyPlugin, {
    // the next line is important! You need to provide the Vuetify Object to this place.
    vuetify: vuetify, // same as "vuetify: vuetify"
    // optional, default to 'md' (default vuetify icons before v2.0.0)
    iconsGroup: 'mdi'
})

export default vuetify