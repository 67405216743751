import axios from 'axios';
import Raven from 'raven-js';
import store from '../store/index';
import router from "@/router";
import i18n from "@/plugins/i18n";

/**
 * Create a new Axios client instance
 * @see https://github.com/mzabriskie/axios#creating-an-instance
 */
const getClient = (baseUrl = process.env.VUE_APP_API_URL) => {

    const authenticated = store.getters['auth/isAuthenticated']

    const options = {
        baseURL: baseUrl,
        withCredentials: true
    };

    if (authenticated) {
        options.headers = {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'X-VENCHI-STORE': store.getters['auth/sc']
        };
    }
    // console.log('API CLIENT options ' , options)

    const client = axios.create(options);

    // Add a request interceptor
    client.interceptors.request.use(
        requestConfig => requestConfig,
        (requestError) => {
            Raven.captureException(requestError);

            return Promise.reject(requestError);
        },
    );

    // Add a response interceptor
    client.interceptors.response.use(function (response) {
        return response;
    },
    (error) => {

        if (error.response.status === 401) {
            store.dispatch('snackbar/showSnackBar', {message: i18n.t('commons.errors.unauthorized'), type: 'error'})
            store.dispatch('auth/forceLogout')
                .then(() => {
                    if (router.currentRoute.path !== '/login') {
                        router.push('/login')
                    }
                })
        }

        if (error.response.status === 403 && !authenticated) {
            store.dispatch('snackbar/showSnackBar', {message: i18n.t('commons.errors.unauthenticated'), type: 'error'})
            if (router.currentRoute.path !== '/login') {
                router.push('/login')
            }
        }

       // if (error.response.status === 422) {
       //      store.dispatch('snackbar/showSnackBar', {message: i18n.t('commons.errors.unauthenticated'), type: 'error', err: error.response})
       //
       //  }

        if (error.response.status >= 500) {
            store.dispatch('snackbar/showSnackBar', {message: i18n.t('commons.errors.generic'), type: 'error'})
        }
        return Promise.reject(error);
    });

    return client;
};

/**
 * Base HTTP Client
 */
export default {
    // Provide request methods with the default base_url
    get(url, conf = {}) {
        console.log('API CLIENT | GET ' , url, conf)
        return getClient().get(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    delete(url, conf = {}) {
        console.log('API CLIENT | DELETE ' , url, conf)
        return getClient().delete(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    head(url, conf = {}) {
        console.log('API CLIENT | HEAD ' , url, conf)
        return getClient().head(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    options(url, conf = {}) {
        console.log('API CLIENT | OPTIONS ' , url, conf)
        return getClient().options(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    post(url, data = {}, conf = {}) {
        console.log('API CLIENT | POST ' , url, conf)
        return getClient().post(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    put(url, data = {}, conf = {}) {
        console.log('API CLIENT | PUT ' , url, conf)
        return getClient().put(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    patch(url, data = {}, conf = {}) {
        console.log('API CLIENT | PUT ' , url, conf)
        return getClient().patch(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },
};
