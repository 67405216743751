import moment from "moment";

export default {
    namespaced: true,
    state: {
        statsRange: {
            start: moment().subtract('7', 'days').toISOString().substr(0, 10),
            end: moment().subtract('1', 'day').toISOString().substr(0, 10),
            country: 'it'
        }
    },

    getters: {
        getStatsRange(state) {
            return state.statsRange
        }
    },

    actions: {
        setStatsRange({commit}, payload) {
            commit('statsRange', payload)
        }
    },

    mutations: {
        statsRange(state, payload) {
            state.statsRange = payload
        },

        resetRange(state) {
            state.statsData= {}
            state.statsRange = {
                start: moment().subtract('7', 'days').toISOString().substr(0, 10),
                end: moment().toISOString().substr(0, 10),
                country: 'it'
            }
        },
    }
}
