import apiClient from "@/plugins/apiClient";
import i18n from "@/plugins/i18n";

export default {
    namespaced: true,
    state: {
        isLoading: false
    },

    getters: {
        loadingState(state) {
            return state.isLoading
        },
    },

    actions: {
        manage({commit, dispatch}, payload) {
            console.log('STORE POINT | ACTIONS | add | payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('toggleLoading', true)

                apiClient.post('users/' + payload.user + '/points/manage', payload)
                    .then(resp => {
                        dispatch('snackbar/showSnackBar', {message: i18n.t('users.points.add.messages.success'), type: 'success'}, {root: true})
                        dispatch('user/fetchUser', payload.user, {root: true})
                        commit('toggleLoading', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('toggleLoading', false, {root: false})
                        dispatch('snackbar/showSnackBar', {message: i18n.t('users.points.add.messages.fail'), type: 'error', err: err.response, timeout: 8000}, {root: true})
                        reject(err);
                    });
            });
        }
    },

    mutations: {
        toggleLoading(state, payload) {
            state.isLoading = payload
        }
    }
}