<template>
  <v-main>
    <v-container
        class="fill-height"
        fluid

    >
      <v-row
          align="center"
          justify="center"
      >

        <v-col
            :cols="cols[0].cols"
            :xl="cols[1].xl"
            :lg="cols[2].lg"
            :sm="cols[3].sm"
            :md="cols[4].md"
        >

          <slot></slot>

        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
name: "CenterContentTemplate",

  props: {
    cols: {
      type: Array,
      default: () => {
        return [
          {cols: 12},
          {xl: 3},
          {lg: 4},
          {sm: 6},
          {md: 6},
        ]
      }
    }
  }
}
</script>

<style scoped>

</style>