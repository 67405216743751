import apiClient from '../../plugins/apiClient'
import i18n from "@/plugins/i18n";
const querystring = require('querystring');

export default {
    namespaced: true,
    state: {
        isLoading: false,
        isLoadingAll: false,
        thresholds: [],
        allThresholds: [],
        pagination: {
            current: 1,
            total: 0,
            per_page: 15
        },
    },

    getters: {
        loadingState(state) {
            return state.isLoading
        },

        loadingAllState(state) {
            return state.isLoadingAll
        },

        getthresholds(state) {
          return state.thresholds
        },

        getAllThresholds(state) {
          return state.allThresholds
        },

        getthreshold(state) {
            return state.threshold
        },

        getPagination(state) {
            return state.pagination
        },
    },

    actions: {
        fetchthresholds({commit, state, rootGetters}, payload) {
            commit('loading', true)
            console.log('STORE threshold | ACTIONS | fetchthreshold')
            if (rootGetters["auth/canManageAppData"]) {
                return new Promise((resolve, reject) => {

                    let qs = ''
                    if (payload) {
                        qs = '&' + querystring.stringify(payload);
                    }

                    apiClient.get('thresholds?page=' + state.pagination.current + qs)
                        .then(resp => {
                            commit('loading', false)
                            commit('thresholds', resp.data.data)
                            commit('pagination', resp.data.meta)
                            resolve(resp)
                        })
                        .catch(err => {
                            commit('loading', false)
                            reject(err)
                        })
                })
            }
        },

        fetchAllThresholds({commit}, language) {
            commit('loadingAllThreshold', true)
            console.log('STORE threshold | ACTIONS | fetchAllThresholds')
            return new Promise((resolve, reject) => {
                apiClient.get('thresholds/all?lang=' + language)
                    .then(resp => {
                        commit('loadingAllThreshold', false)
                        commit('allThresholds', resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('loadingAllThreshold', false)
                        reject(err)
                    })
            })
        },

        storethreshold({commit, dispatch}, payload) {
            console.log('STORE threshold | ACTIONS | storethreshold payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.post('thresholds', payload)
                    .then(resp => {
                        commit('loading', false)
                        dispatch('snackbar/showSnackBar', {message: i18n.t('coupons.thresholds.messages.create.success'), type: 'success'}, {root: true})
                        dispatch('fetchthresholds')
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false, {root: false})
                        dispatch('snackbar/showSnackBar', {message: i18n.t('coupons.thresholds.messages.create.fail'), type: 'error', err: err.response, timeout: 8000}, {root: true})
                        reject(err);
                    });
            });
        },

        updatethreshold({commit, dispatch}, payload) {
            console.log('STORE threshold | ACTIONS | updatethreshold payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.patch('thresholds/' + payload.id, payload)
                    .then(resp => {
                        dispatch('snackbar/showSnackBar', {message: i18n.t('coupons.thresholds.messages.update.success'), type: 'success'}, {root: true})
                        dispatch('fetchthresholds')
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false)
                        dispatch('snackbar/showSnackBar', {message: i18n.t('coupons.thresholds.messages.update.fail'), type: 'error', err: err.response, timeout: 8000}, {root: true})
                        reject(err);
                    });
            });
        },

        setLoadingStatus({commit}, payload) {
            commit('loading', payload)
        },
    },

    mutations: {
        thresholds(state, payload) {
            state.thresholds = payload
        },

        allThresholds(state, payload) {
            state.allThresholds = payload
        },

        pagination(state, payload) {
            state.pagination.current = payload.current_page
            state.pagination.total = payload.last_page
            state.pagination.per_page = payload.par_page
        },

        loading(state, payload) {
            state.isLoading = payload
        },

        loadingAllThreshold(state, payload) {
            state.isLoadingAll = payload
        },

        reset(state) {
            state.isLoading= false
            state.coupons= []
            state.pagination= {
                current: 1,
                total: 0,
                per_page: 15
            }
        }
    },
}
