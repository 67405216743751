import apiClient from '../../plugins/apiClient'
import i18n from "@/plugins/i18n";

export default {
    namespaced: true,
    state: {
        isWorking: false,
        isLoading: false,
        validResetPasswordToken: true,
    },

    getters: {
        isWorking: state => state.isWorking,
        loadingState: state => state.isLoading,
        getIsValidResetPasswordToken: state => state.validResetPasswordToken
    },

    actions: {
        sendRecoveryPasswordEmail({ commit, dispatch }, email) {
            commit('setIsWorking', true)
            return new Promise((resolve, reject) => {
                apiClient.post("password/recovery", {
                    email: email
                })
                    .then(resp => {
                        commit('setIsWorking', false)
                        dispatch('snackbar/showSnackBar', {message: i18n.t('account.password.forgot.send_email.success'), type: 'success'}, {root: true})
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('setIsWorking', false)
                        console.log('store auth actions AUTH_REQUEST error ', err)
                        dispatch('snackbar/showSnackBar', {message: i18n.t('account.password.forgot.send_email.fail'), type: 'error'}, {root: true})
                        reject(err);
                    });
            })
        },

        verifyResetPasswordToken({commit}, payload) {
            console.log('STORE ACCCOUNT | ACTIONS | verifyResetPasswordToken payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.get('password/recovery/verify/' + payload)
                    .then(resp => {
                        commit('validResetPasswordTokenToken', true)
                        commit('loading', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('validResetPasswordTokenToken', false)
                        commit('loading')
                        reject(err);
                    });
            });
        },

        resetPassword({commit, dispatch}, payload) {
            commit('loading', true)
            return new Promise((resolve, reject) => {
                apiClient.patch("password/reset", {
                    password: payload.password,
                    token: payload.token
                })
                    .then(resp => {
                        commit('loading', false)
                        dispatch('snackbar/showSnackBar', {message: i18n.t('account.password.reset.success'), type: 'success'}, {root: true})
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false)
                        console.log('store auth actions AUTH_REQUEST error ', err)
                        dispatch('snackbar/showSnackBar', {message: i18n.t('account.password.reset.fail'), type: 'error'}, {root: true})
                        reject(err);
                    });
            })
        }
    },

    mutations: {
        setIsWorking(state, payload) {
            state.isWorking = payload
        },

        validResetPasswordTokenToken(state, payload)
        {
            state.validResetPasswordToken = payload
        },

        loading(state, payload) {
            state.isLoading = payload
        },
    },
}