import moment from "moment";

const Utils = {
    install(Vue, options) {

        Vue.prototype.$parseDate = function (date) {
            return (date) ? moment(date).format('DD-MM-Y') : '-'
        }

        Vue.prototype.$parseDateTime = function (date) {
            return (date) ? moment(date).format('DD-MM-Y HH:mm') : '-'
        }

        Vue.prototype.$parseTime = function (date) {
            return (date) ? moment(date).format('HH:mm') : '-'
        }
    }
};

export default Utils;