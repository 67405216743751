import Vue from "vue";
import Router from "vue-router";
import Login from "../views/Login";
import store from "../store";
import Layout from "../views/Layout";
import i18n from "@/plugins/i18n";

Vue.use(Router);

const router = new Router({
    mode: "history",
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/confirm-invite/:token',
            name: 'ConfirmInvite',
            component: () => import(/* webpackChunkName: "admin-group" */ '../views/Registration'),
        },
        {
            path: '/password/forgot',
            name: 'forgotPassowrd',
            component: () => import(/* webpackChunkName: "account-group" */ '../views/account/ForgotPassword'),
        },
        {
            path: '/password/reset/:token',
            name: 'resetPassowrd',
            component: () => import(/* webpackChunkName: "account-group" */ '../views/account/ResetPassword'),
        },
        {
            path: '/',
            name: 'Main',
            component: Layout,
            meta: {
                auth: true,
                title: i18n.t('dashboard.title'),
                breadCrumbs: [
                    {
                        text: i18n.t('dashboard.title'),
                        disabled: true,
                        href: '/dashboard',
                    }
                ]
            },
            children: [
                {
                    name: 'dashboard',
                    path: 'dashboard',
                    component: () => import(/* webpackChunkName: "dashboard-group" */ '../views/Dashboard.vue'),
                    meta: {
                        title: i18n.t('dashboard.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: true,
                                href: '/dashboard',
                            }
                        ]
                    }
                },

                {
                    name: 'passport-client-credentials',
                    path: 'oauth',
                    component: () => import(/* webpackChunkName: "oauth-group" */ '../views/passport/Client.vue'),
                    meta: {
                        title: i18n.t('oauth.client_credentials.list.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: true,
                                href: '/dashboard',
                            }
                        ]
                    }
                },

                {
                    name: 'admin-options',
                    path: 'admin-options',
                    component: () => import(/* webpackChunkName: "options-group" */ '../views/venchiOptions/List'),
                    meta: {
                        scope: ['admin', 'operator'],
                        title: i18n.t('options.list.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: false,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('options.list.title'),
                                disabled: true,
                                href: '/admin-options',
                            }
                        ]
                    }
                },

                {
                    name: 'admin-users',
                    path: 'admin-users',
                    component: () => import(/* webpackChunkName: "admin-group" */ '../views/adminUsers/List'),
                    meta: {
                        scope: ['admin', 'operator'],
                        title: i18n.t('users.list.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: false,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('users.list.title'),
                                disabled: true,
                                href: '/admin-users',
                            }
                        ]
                    }
                },

                {
                    name: 'users',
                    path: 'users',
                    component: () => import(/* webpackChunkName: "users-group" */ '../views/users/List'),
                    meta: {
                        scope: ['admin', 'operator', 'analyst'],
                        title: i18n.t('users.list.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: false,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('users.list.title'),
                                disabled: true,
                                href: '/users',
                            }
                        ]
                    }
                },
                {
                    name: 'user-profile',
                    path: 'users/:id',
                    component: () => import(/* webpackChunkName: "users-group" */ '../views/users/Profile'),
                    meta: {
                        scope: ['admin', 'operator','analyst'],
                        title: i18n.t('users.profile.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: false,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('users.list.title'),
                                disabled: false,
                                href: '/users',
                            },
                            {
                                text: i18n.t('users.profile.title'),
                                disabled: true,
                                href: '/users/{id}',
                            }
                        ]
                    }
                },

                {
                    name: 'missions',
                    path: 'missions',
                    component: () => import(/* webpackChunkName: "missions-group" */ '../views/missions/List'),
                    meta: {
                        scope: ['admin', 'operator'],
                        title: i18n.t('missions.list.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: false,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('missions.list.title'),
                                disabled: true,
                                href: '/missions',
                            }
                        ]
                    }
                },
                {
                    name: 'mission-details',
                    path: 'missions/:type/:id',
                    component: () => import(/* webpackChunkName: "missions-group" */ '../views/missions/Show'),
                    meta: {
                        scope: ['admin', 'operator'],
                        title: i18n.t('missions.list.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: false,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('missions.list.title'),
                                disabled: false,
                                href: '/missions',
                            },
                            {
                                text: i18n.t('missions.details.title'),
                                disabled: true,
                                href: '/missions/:id',
                            }
                        ]
                    }
                },


                {
                    name: 'coupons',
                    path: 'coupons',
                    component: () => import(/* webpackChunkName: "coupons-group" */ '../views/coupons/List'),
                    meta: {
                        scope: ['admin', 'operator'],
                        title: i18n.t('coupons.list.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: false,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('coupons.list.title'),
                                disabled: true,
                                href: '/coupons',
                            }
                        ]
                    }
                },

                {
                    name: 'ecommerce-coupon-codes',
                    path: 'ecommerce-coupon-codes',
                    component: () => import(/* webpackChunkName: "coupons-group" */ '../views/coupons/ecommerceCodes/List'),
                    meta: {
                        scope: ['admin', 'operator', 'analyst'],
                        title: i18n.t('coupons.list.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: false,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('coupons.list.title'),
                                disabled: true,
                                href: '/coupons',
                            }
                        ]
                    }
                },

                {
                    name: 'products',
                    path: 'products',
                    component: () => import(/* webpackChunkName: "products-group" */ '../views/products/List'),
                    meta: {
                        scope: ['admin', 'operator'],
                        title: i18n.t('products.list.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: false,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('products.list.title'),
                                disabled: true,
                                href: '/products',
                            }
                        ]
                    }
                },

                {
                    name: 'api-fails',
                    path: 'api-fails',
                    component: () => import(/* webpackChunkName: "admin-group" */ '../views/apiFails/List'),
                    meta: {
                        scope: ['admin'],
                        title: i18n.t('api-fails.list.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: true,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('api-fails.list.title'),
                                disabled: true,
                                href: '/api-fails',
                            }
                        ]
                    }
                },

                {
                    name: 'receipts-modifier',
                    path: 'receipts-modifier',
                    component: () => import(/* webpackChunkName: "admin-group" */ '../views/receiptsModifier/List'),
                    meta: {
                        scope: ['admin'],
                        title: i18n.t('receipts-modifier.list.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: true,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('receipts-modifier.list.title'),
                                disabled: true,
                                href: '/receipts-modifier',
                            }
                        ]
                    }
                },

                {
                    name: 'overlays',
                    path: 'overlays',
                    component: () => import(/* webpackChunkName: "admin-group" */ '../views/overlays/List'),
                    meta: {
                        scope: ['admin'],
                        title: i18n.t('overlays.list.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: true,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('overlays.list.title'),
                                disabled: true,
                                href: '/overlays',
                            }
                        ]
                    }
                },

                {
                    name: 'exports',
                    path: 'exports',
                    component: () => import(/* webpackChunkName: "admin-group" */ '../views/exports/List'),
                    meta: {
                        scope: ['admin'],
                        title: i18n.t('exports.list.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: true,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('exports.list.title'),
                                disabled: true,
                                href: '/exports',
                            }
                        ]
                    }
                },

                {
                    name: 'stores-list',
                    path: 'stores',
                    component: () => import(/* webpackChunkName: "stores-group" */ '../views/stores/List'),
                    meta: {
                        scope: ['admin', 'operator', 'cc'],
                        title: i18n.t('stores.list.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: false,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('stores.list.title'),
                                disabled: true,
                                href: '/stores',
                            }
                        ]
                    }
                },
                {
                    name: 'stores-details',
                    path: 'store/:id',
                    component: () => import(/* webpackChunkName: "stores-group" */ '../views/stores/Show'),
                    meta: {
                        scope: ['admin','cc'],
                        title: i18n.t('stores.details.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: false,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('stores.list.title'),
                                disabled: false,
                                href: '/stores',
                            },
                            {
                                text: i18n.t('stores.details.title'),
                                disabled: true,
                                href: '/stores',
                            }
                        ]
                    }
                },
                {
                    name: 'my-store',
                    path: 'my-store',
                    component: () => import(/* webpackChunkName: "stores-group" */ '../views/stores/Show'),
                    meta: {
                        scope: ['admin', 'operator', 'analyst', 'store', 'cc'],
                        title: i18n.t('stores.details.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: true,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('stores.details.title'),
                                disabled: true,
                                href: '/stores',
                            }
                        ]
                    }
                },

                {
                    name: 'pages-list',
                    path: 'pages',
                    component: () => import(/* webpackChunkName: "pages-group" */ '../views/pages/List'),
                    meta: {
                        scope: ['admin', 'operator'],
                        title: i18n.t('pages.list.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: false,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('pages.list.title'),
                                disabled: true,
                                href: '/pages',
                            }
                        ]
                    }
                },

                {
                    name: 'news-list',
                    path: 'news',
                    component: () => import(/* webpackChunkName: "pages-group" */ '../views/news/List'),
                    meta: {
                        scope: ['admin', 'operator'],
                        title: i18n.t('news.list.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: false,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('news.list.title'),
                                disabled: true,
                                href: '/news',
                            }
                        ]
                    }
                },

                {
                    name: 'discount-codes',
                    path: 'discount-codes',
                    component: () => import(/* webpackChunkName: "pages-group" */ '../views/discountcode/List'),
                    meta: {
                        scope: ['admin', 'operator'],
                        title: i18n.t('discountcode.list.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: false,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('discountcode.list.title'),
                                disabled: true,
                                href: '/discountcode',
                            }
                        ]
                    }
                },

                {
                    name: 'images-list',
                    path: 'images',
                    component: () => import(/* webpackChunkName: "images-group" */ '../views/images/List'),
                    meta: {
                        scope: ['admin', 'operator'],
                        title: i18n.t('images.list.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: false,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('images.list.title'),
                                disabled: true,
                                href: '/images',
                            }
                        ]
                    }
                },

                {
                    name: 'avatars-list',
                    path: 'avatars',
                    component: () => import(/* webpackChunkName: "avatars-group" */ '../views/avatars/List'),
                    meta: {
                        scope: ['admin', 'operator'],
                        title: i18n.t('avatars.list.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: false,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('avatars.list.title'),
                                disabled: true,
                                href: '/avatars',
                            }
                        ]
                    }
                },

                {
                    name: 'coupon-thresholds-list',
                    path: 'coupon-thresholds',
                    component: () => import(/* webpackChunkName: "settings-group" */ '../views/coupons/thresholds/List'),
                    meta: {
                        scope: ['admin', 'operator'],
                        title: i18n.t('coupons.thresholds.list.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: false,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('coupons.thresholds.title'),
                                disabled: true,
                                href: '/coupon-thresholds',
                            }
                        ]
                    }
                },

                {
                    name: 'push-notifications',
                    path: 'push-notifications',
                    component: () => import(/* webpackChunkName: "push-notification-group" */ '../views/pushNotifications/List'),
                    meta: {
                        scope: ['admin', 'operator'],
                        title: i18n.t('pushnotifications.list.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: false,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('pushnotifications.list.title'),
                                disabled: true,
                                href: '/push-notifications',
                            }
                        ]
                    }
                },

                {
                    name: 'campaign-codes',
                    path: 'campaign-codes',
                    component: () => import(/* webpackChunkName: "campaign-codes-group" */ '../views/campaignCodes/List'),
                    meta: {
                        scope: ['admin', 'operator'],
                        title: i18n.t('campaign-codes.list.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: false,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('campaign-codes.list.title'),
                                disabled: true,
                                href: '/campaign-codes',
                            }
                        ]
                    }
                },

                {
                    name: 'analytics-users',
                    path: 'analytics/users',
                    component: () => import(/* webpackChunkName: "analitycs-group" */ '../views/analytics/Users'),
                    meta: {
                        scope: ['admin', 'operator', 'analyst'],
                        title: i18n.t('analytics.users.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: false,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('analytics.users.title'),
                                disabled: true,
                                href: '/analytics/users',
                            }
                        ]
                    }
                },
                {
                    name: 'analytics-coupons',
                    path: 'analytics/coupons',
                    component: () => import(/* webpackChunkName: "analitycs-group" */ '../views/analytics/Coupons'),
                    meta: {
                        scope: ['admin', 'operator', 'analyst'],
                        title: i18n.t('analytics.coupons.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: false,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('analytics.coupons.title'),
                                disabled: true,
                                href: '/analytics/coupons',
                            }
                        ]
                    }
                },
                {
                    name: 'analytics-receipts',
                    path: 'analytics/receipts',
                    component: () => import(/* webpackChunkName: "analitycs-group" */ '../views/analytics/Receipts'),
                    meta: {
                        scope: ['admin', 'operator', 'analyst'],
                        title: i18n.t('analytics.receipts.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: false,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('analytics.receipts.title'),
                                disabled: true,
                                href: '/analytics/receipts',
                            }
                        ]
                    }
                },
                {
                    name: 'analytics-missions',
                    path: 'analytics/missions',
                    component: () => import(/* webpackChunkName: "analitycs-group" */ '../views/analytics/Missions'),
                    meta: {
                        scope: ['admin', 'operator', 'analyst'],
                        title: i18n.t('analytics.missions.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: false,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('analytics.missions.title'),
                                disabled: true,
                                href: '/analytics/missions',
                            }
                        ]
                    }
                },

                {
                    name: 'qrcodes-bill-create',
                    path: 'bill-qrcode/create',
                    component: () => import(/* webpackChunkName: "ecommerce-group" */ '../views/qrcodes/Create'),
                    meta: {
                        scope: ['admin', 'analyst'],
                        title: i18n.t('qrcode.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: false,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('qrcode.title'),
                                disabled: true,
                                href: '/bill-qrcode/create',
                            }
                        ]
                    }
                },

                {
                    name: 'ecommerce-orders-listing',
                    path: 'ecommerce/orders',
                    component: () => import(/* webpackChunkName: "ecommerce-group" */ '../views/ecommerce/List'),
                    meta: {
                        scope: ['admin', 'operator', 'analyst', 'store', 'cc'],
                        title: i18n.t('ecommerce.orders.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: false,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('ecommerce.orders.title'),
                                disabled: true,
                                href: '/ecommerce/orders',
                            }
                        ]
                    }
                },
                {
                    name: 'ecommerce-order-details',
                    path: 'ecommerce/orders/:id',
                    component: () => import(/* webpackChunkName: "ecommerce-group" */ '../views/ecommerce/Details'),
                    meta: {
                        scope: ['admin', 'operator', 'analyst', 'store', 'cc'],
                        title: i18n.t('ecommerce.orders.details.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: false,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('ecommerce.orders.title'),
                                disabled: false,
                                href: '/ecommerce/orders',
                            },
                            {
                                text: i18n.t('ecommerce.orders.details.title'),
                                disabled: true,
                                href: '/ecommerce/orders/:id',
                            }
                        ]
                    }
                },

                {
                    name: 'venchi-reports',
                    path: 'reports',
                    component: () => import(/* webpackChunkName: "venchi-reports-group" */ '../views/reports/Reports'),
                    meta: {
                        scope: ['admin'],
                        title: i18n.t('reports.title'),
                        breadCrumbs: [
                            {
                                text: i18n.t('dashboard.title'),
                                disabled: false,
                                href: '/dashboard',
                            },
                            {
                                text: i18n.t('reports.title'),
                                disabled: true,
                                href: '/reports',
                            }
                        ]
                    }
                },
            ]
        }
    ]
});

router.beforeEach((to, from, next) => {

    let authenticated = store.state.auth.authenticated
    let scope = store.getters['auth/scope']

    if (to.matched.some(m => m.meta.auth) && !authenticated && to.path !== '/login') {
        next('/login');
    } else if (authenticated && to.path === '/login') {
        next('/dashboard')
    } else {
        if (!Object.prototype.hasOwnProperty.call(to.meta, 'scope') || to.meta.scope.includes(scope)) {
            next()
        } else {
            store.dispatch('snackbar/showSnackBar', {message: i18n.t('commons.errors.not-permitted'), type: 'error'})
        }
    }
});

export default router
