<template>
  <v-app id="inspire">

    <navigation-drawer></navigation-drawer>

    <v-main>
      <v-toolbar :style="'z-index:1;position:fixed;width:100%;max-width:'+toolbarWidth+'px;'"
                 dstyle="xtop:toolbarHeight + 'px'}"
                 short
      >
        <!--                <v-toolbar-title>{{$route.meta.title}} </v-toolbar-title>-->
        <v-row align="center"
               justify="center"
        >
          <v-col>
            <v-toolbar-title>{{ $route.meta.title }}</v-toolbar-title>
          </v-col>
          <v-col class="text-right">
            <v-breadcrumbs
                :items="breadItems"
                class="float-right py-0 d-none d-md-flex d-lg-flex d-xl-flex"
            />
          </v-col>
        </v-row>
      </v-toolbar>

      <v-container
          :style="'padding-top: ' + toolbarHeight + 'px'"
      >

        <v-sheet class="px-lg-15">
          <v-row
              align="start"
              justify="start"
          >
            <v-col cols="12">
              <router-view></router-view>
            </v-col>
          </v-row>
        </v-sheet>
        <snackbar></snackbar>
      </v-container>

    </v-main>

    <v-footer v-if="!bottomBar"
              app
              class="font-weight-medium"
              absolute
              inset
    >
      <v-col
          class="text-center"
          cols12
      >
        <small>&copy; {{ new Date().getFullYear() }} - A-Tono</small>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>

import SnackBar from "../components/commons/SnackBar";
import NavigationDrawer from "@/views/Layout/NavigationDrawer";

export default {
  props: {
    source: String,
  },

  data: () => ({
    token: localStorage.getItem('user-token'),
    activeBtn: 1,
  }),

  components: {
    'navigation-drawer': NavigationDrawer,
    'snackbar': SnackBar
  },

  mounted() {
    this.$store.dispatch('image/fetchImages')
    this.$store.dispatch('couponThreshold/fetchthresholds')
    this.$store.dispatch('product/fetchProducts')
    this.$i18n.locale = localStorage.getItem('locale')
  },

  created() {
    this.$nextTick(function () {
      this.$store.dispatch('auth/setScopes')
    })
  },

  computed: {
    breadItems() {
      return this.$route.meta.breadCrumbs
    },
    toolbarHeight: function () {
      return this.$vuetify.application.top
    },
    toolbarWidth: function () {
      return ((window.innerWidth - this.$vuetify.application.left) + 10);
    },
    bottomBar() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },

}
</script>

<style lang="scss">
@import 'node_modules/vuetify/src/styles/styles';
@import "../sass/_partials/data-table";
@import "../sass/_partials/typography";
</style>
