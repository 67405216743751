import Vue from 'vue'
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

import { languages } from '@/locales/index.js'
import { defaultLocale } from '@/locales/index.js'

const messages = Object.assign(languages)

const numberFormats = {
    'it': {
        currency: {
            style: 'currency', currency: 'EUR', currencyDisplay: 'symbol'
        }
    },
    'en': {
        currency: {
            style: 'currency', currency: 'GBP', currencyDisplay: 'symbol'
        }
    },
    'us': {
        currency: {
            style: 'currency', currency: 'US', currencyDisplay: 'symbol'
        }
    },
}

const i18n = new VueI18n({
    locale: defaultLocale,
    messages,
    numberFormats,
    silentTranslationWarn: true
})

export default i18n