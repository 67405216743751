// index.js
import commons from './commons.json'
import dashboard from './dashboard.json'
import sidebar from './sidebar.json'
import icons from './../icons.json'
import users from './users.json'
import missions from './missions.json'
import coupons from './coupons.json'
import auth from './auth.json'
import stores from './stores.json'
import images from './images.json'
import avatars from './avatars.json'
import pages from './pages.json'
import discountcode from './discountcode.json'
import products from './products.json'
import options from './options.json'
import search from './search.json'
import analytics from './analytics.json'
import pushnotifications from './pushnotifications.json'
import ecommerce from './ecommerce.json'
import notes from './notes.json'
import account from './account.json'
import campaign_codes from './campign_codes.json'
import qrcode from './qrcode.json'
import oauth from './oauth.json'
import receipts from './receipts.json'
import reports from './reports.json'

const it = {
    commons: commons,
    auth: auth,
    icons: icons,
    sidebar: sidebar,
    dashboard: dashboard,
    users: users,
    missions: missions,
    coupons: coupons,
    stores: stores,
    images: images,
    avatars: avatars,
    pages: pages,
    products: products,
    options: options,
    discountcode:discountcode,
    search: search,
    analytics: analytics,
    pushnotifications: pushnotifications,
    ecommerce: ecommerce,
    notes: notes,
    account: account,
    qrcode: qrcode,
    oauth: oauth,
    campaign_codes: campaign_codes,
    receipts: receipts,
    reports: reports
}

export default it