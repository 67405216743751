import apiClient from '@/plugins/apiClient'

export default {
     namespaced: true,
     state:{
          export: null,
          exports: [],
          meta: {
               current_page: 1,
               total: 0,
               per_page: 12
          },
          errors: {},
          message: null,
          loading: null
     },
     getters:{
          export: state => state.export,
          exports: state => state.exports,
          meta: state => state.meta,
          errors: state => state.errors,
          message: state => state.message,
          loading: state => state.loading,
     },
     mutations:{
          setLoading(state, val){
               state.loading = val
          },
          setexport(state,resp){
               console.log('mutation setexport',resp);
               state.export = resp
          },
          setexports(state,resp){
               console.log('mutation setexports',resp.data);
               state.exports = resp.data
               console.log('mutation setMeta',resp.meta);
               state.meta = resp.meta
          },
          setDownload(state,resp){
               console.log('mutation setDownload',resp.meta);
               state.export.download = resp.download
          },
          error(state, resp) {
               console.log('mutation setErrors',resp);
               state.errors = resp.errors
               state.message = resp.message
          },
          success(state, resp) {
               console.log('mutation success',resp);
               state.errors = {}
          },
          addTo(state,resp){
               console.log('mutation addTo',resp);
               state.exports.unshift(resp)
          },
     },
     actions:{
          list({commit,state}, params){
               commit('setLoading', true);
               return new Promise((resolve, reject) => {
                   console.log('GET exports on page: '+state.meta.current_page, params)

                   apiClient.get("exports?page="+state.meta.current_page).then(resp => {
                         commit('setexports', resp.data);
                         commit('success', resp.data);
                         commit('setLoading', false);
                         resolve(resp);
                   })
                   .catch(err => {
                       console.log('GET pages ERROR', err)
                       commit('error', err.response);
                       commit('setLoading', false);
                       reject(err);
                   });
               })
          },
          create({commit},data){
               commit('setLoading', true);
               return new Promise((resolve, reject) => {
                    console.log('create single sexport',data)
                    apiClient.post("exports/create/", data).then(resp => {
                         commit('addTo', resp.data);
                         commit('success', resp.data);
                         commit('setLoading', false);
                         resolve(resp);
                    })
                    .catch(err => {
                         console.log('create single sexport ERROR', err)
                         commit('error', err.response.data);
                         commit('setLoading', false);
                         reject(err);
                    });
               })
          },
          update({commit},data){
               commit('setLoading', true);
               return new Promise((resolve, reject) => {
                    console.log('create single sexport',data)
                    apiClient.patch("exports/update/"+data.id, data).then(resp => {
                         commit('success', resp.data);
                         commit('setLoading', false);
                         resolve(resp);
                    })
                    .catch(err => {
                         console.log('create single export ERROR', err)
                         commit('error', err.response.data);
                         commit('setLoading', false);
                         reject(err);
                    });
               })
          },
          download({commit},data){
               commit('setLoading', true);
               commit('setexport', data);
               return new Promise((resolve, reject) => {
                    console.log('create single sexport',data)
                    apiClient.post("exports/download/"+data.id, data).then(resp => {
                         commit('success', resp.data);
                         commit('setDownload', resp.data);
                         commit('setLoading', false);
                         resolve(resp);
                    })
                    .catch(err => {
                         console.log('create single export ERROR', err)
                         commit('error', err.response.data);
                         commit('setLoading', false);
                         reject(err);
                    });
               })
          },
          generate({commit},data){
               commit('setLoading', true);
               return new Promise((resolve, reject) => {
                    console.log('create single sexport',data)
                    apiClient.post("exports/generate/"+data.id, data).then(resp => {
                         commit('success', resp.data);
                         commit('setLoading', false);
                         resolve(resp);
                    })
                    .catch(err => {
                         console.log('create single export ERROR', err)
                         commit('error', err.response.data);
                         commit('setLoading', false);
                         reject(err);
                    });
               })
          },
          regenerate({commit},country){
               commit('setLoading', true);
               return new Promise((resolve, reject) => {
                    console.log('regenerate single ',country)
                    apiClient.post("exports/regenerate/"+country).then(resp => {
                         commit('success', resp.data);
                         commit('setLoading', false);
                         resolve(resp);
                    })
                    .catch(err => {
                         console.log('create single export ERROR', err)
                         commit('error', err.response.data);
                         commit('setLoading', false);
                         reject(err);
                    });
               })
          },
          saveonftp({commit},data){
               commit('setLoading', true);
               return new Promise((resolve, reject) => {
                    console.log('create single sexport',data)
                    apiClient.post("exports/save-on-ftp/"+data.id, data).then(resp => {
                         commit('success', resp.data);
                         commit('setLoading', false);
                         resolve(resp);
                    })
                    .catch(err => {
                         console.log('create single export ERROR', err)
                         commit('error', err.response.data);
                         commit('setLoading', false);
                         reject(err);
                    });
               })
          },
     }
}
