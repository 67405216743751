import apiClient from '../../plugins/apiClient'
import i18n from "@/plugins/i18n";
const querystring = require('querystring');

export default {
    namespaced: true,
    state: {
        isLoading: false,
        fetiching: false,
        filters: [],
        types: [],
        qrcodeProducts: [],
        coupons: [],
        coupon: {},
        ecommerceValues: [],
        fetchCouponTypeValues: false,
        fetchCouponProductsValues: false,
        qrcodeTypes: [],
        qrCodeValues: [],
        pagination: {
            current: 1,
            total: 0,
            per_page: 15
        },
    },

    getters: {
        loadingState(state) {
            return state.isLoading
        },

        fetchingStatus(state) {
            return state.fetiching
        },

        fetchCouponTypeValuesStatus(state) {
            return state.fetchCouponTypeValues
        },

        fetchCouponProductValuesStatus(state) {
            return state.fetchCouponProductsValues
        },

        getTypes(state) {
          return state.types
        },

        getFilters(state) {
            return state.filters
        },

        getEcommerceValues(state) {
          return state.ecommerceValues
        },

        getQrcodeValues(state) {
          return state.qrCodeValues
        },

        getQrcodeTypes(state) {
          return state.qrcodeTypes
        },

        getQrcodeProducts(state) {
          return state.qrcodeProducts
        },

        getCoupons(state) {
            return state.coupons
        },

        getCoupon(state) {
            return state.coupon
        },

        getCouponById: (state) => (id) => {
            console.log('STORE COUPON | GETTERS | getCouponById ' , id)
            console.log('STORE COUPON | GETTERS | coupons ' , state.coupons)
            return state.coupons.find(coupon => coupon.id === id)
        },

        getPagination(state) {
            return state.pagination
        },
    },

    actions: {
        setTypes({commit}) {
            console.log('STORE COUPONS | ACTIONS | setTypes')
            return new Promise((resolve, reject) => {
                apiClient.get('coupons/types')
                    .then(resp => {
                        commit('types', resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        
        setQrcodeProducts({commit}, country) {
            console.log('STORE COUPONS | ACTIONS | setProducts country ' , country)
            commit('fetchCouponProductValuesStatus', true)
            return new Promise((resolve, reject) => {
                apiClient.get('coupons/qrcode-products/' + country)
                    .then(resp => {
                        commit('qrcodeProducts', resp.data)
                        commit('fetchCouponProductValuesStatus')
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('fetchCouponProductValuesStatus')
                        reject(err)
                    })
            })
        },

        fetchCoupons({commit, state}, payload) {
            console.log('STORE COUPONS | ACTIONS | fetchCoupons payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('fetching', true)

                // let qs = ''
                // if (payload) {
                //     qs = '&' + querystring.stringify(payload);
                // }

                apiClient.get('coupons?page=' + state.pagination.current + '&' + querystring.stringify(state.filters))
                    .then(resp => {
                        commit('fetching', false)
                        commit('coupons', resp.data.data);
                        commit('pagination', resp.data.meta)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('fetching', false)
                        reject(err);
                    });
            });
        },

        setCoupon({commit}, coupon_id) {

            console.log('STORE COUPONS | ACTIONS | coupon coupon_id ' , coupon_id)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.get('coupons/' + coupon_id)
                    .then(resp => {
                        console.log('resp.data ' , resp.data)
                        console.log('resp.data.data ' , resp.data.data)
                        commit('loading')
                        commit('coupon', resp.data.data)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading')
                        reject(err);
                    });
            });
        },

        fetchEcommerceValues({commit}) {

            console.log('STORE VENCHIOPTION | ACTIONS | fetchEcommerceValues')

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.get('coupons/ecommerce-coupon-values')
                    .then(resp => {
                        commit('ecommerceValues', resp.data)
                        commit('loading', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading')
                        reject(err);
                    });
            });
        },

        fetchQrCodeTypes({commit}) {

            console.log('STORE VENCHIOPTION | ACTIONS | qrCodeTypes')

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.get('coupons/qrcode-coupon-types')
                    .then(resp => {
                        commit('qrCodeTypes', resp.data)
                        commit('loading')
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading')
                        reject(err);
                    });
            });
        },

        fetchQrCodeValues({commit}, payload) {

            console.log('STORE VENCHIOPTION | ACTIONS | fetchQrCodeValues')

            return new Promise((resolve, reject) => {
                commit('fetchCouponTypeValues', true)

                apiClient.get('coupons/qrcode-coupon-values/' + payload)
                    .then(resp => {
                        commit('qrcodeValues', resp.data)
                        commit('fetchCouponTypeValues', false)
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('fetchCouponTypeValues')
                        reject(err);
                    });
            });
        },

        setFilters({commit}, payload) {
          commit('setFilters', payload)
        },

        clearCoupon({commit}) {
          commit('clearCoupon')
        },

        storeCoupon({commit, dispatch}, payload) {
            console.log('STORE COUPONS | ACTIONS | storeCoupon payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.post('coupons', payload)
                    .then(resp => {
                        commit('loading', false)
                        commit('coupon', resp.data.coupon)
                        dispatch('snackbar/showSnackBar', {message: i18n.t('coupons.messages.create.success'), type: 'success'}, {root: true})
                        dispatch('fetchCoupons')
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false, {root: false})
                        dispatch('snackbar/showSnackBar', {message: i18n.t('coupons.messages.create.fail'), type: 'error', err: err.response, timeout: 8000}, {root: true})
                        reject(err);
                    });
            });
        },

        updateCoupon({commit, dispatch}, payload) {
            console.log('STORE COUPONS | ACTIONS | updateCoupon payload ' , payload)

            return new Promise((resolve, reject) => {
                commit('loading', true)

                apiClient.patch('coupons/' + payload.id, payload)
                    .then(resp => {
                        commit('loading', false)
                        dispatch('snackbar/showSnackBar', {message: i18n.t('coupons.messages.update.success'), type: 'success'}, {root: true})
                        dispatch('fetchCoupons')
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('loading', false)
                        dispatch('snackbar/showSnackBar', {message: i18n.t('coupons.messages.update.fail'), type: 'error', err: err.response, timeout: 8000}, {root: true})
                        reject(err);
                    });
            });
        },

        setLoadingStatus({commit}, payload) {
            commit('loading', payload)
        },

        reset({commit}) {
            commit('reset')
        }

    },

    mutations: {
        types(state, payload) {
            state.types = payload
        },

        qrcodeProducts(state, payload) {
            state.qrcodeProducts = payload
        },

        coupons(state, payload) {
            state.coupons = payload
        },

        ecommerceValues(state, payload) {
            console.log('payload ' , payload.data)
            state.ecommerceValues = payload.data.split(',')
        },

        qrcodeValues(state, payload) {
            state.qrCodeValues = payload.data.split(',')
        },

        qrCodeTypes(state, payload) {
            state.qrcodeTypes = payload.data
        },

        parentCoupons(state, payload) {
            state.parentCoupons = payload
        },

        parentCouponCountry(state, payload) {
            state.parentCouponsCountry = payload
        },

        coupon(state, payload) {
            console.log('coupon ' , payload)
            state.coupon = payload
        },

        clearCoupon(state) {
            state.coupon = {}
        },

        pagination(state, payload) {
            state.pagination.current = payload.current_page
            state.pagination.total = payload.last_page
            state.pagination.per_page = payload.par_page
        },

        loading(state, payload) {
            state.isLoading = payload
        },

        setFilters(state, payload) {
            state.filters = payload
        },

        fetchCouponTypeValues(state, payload) {
            state.fetchCouponTypeValues = payload
        },

        fetchCouponProductValuesStatus(state, payload) {
            state.fetchCouponProductValuesStatus = payload
        },

        fetching(state, payload) {
            state.fetiching = payload
        },

        reset(state) {
            state.isLoading= false
            state.coupons= []
            state.pagination= {
                current: 1,
                total: 0,
                per_page: 15
            }
        }
    },
}
